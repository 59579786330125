import React from 'react';
import { Route, Routes, useRoutes } from 'react-router-dom';

import { LoginPage } from 'features/auth/routes/LoginPage';
import { RegisterPage } from 'pages/auth/Register/RegisterPage';
import { ResultSharePage } from 'pages/ResultPage/ResultSharePage';
import { MailAuthenticationPage } from 'pages/auth/MailAuthenticationPage';
import { ReLoginPage } from 'pages/auth/ReLoginPage';
import { ResetPasswordFirstPage } from 'pages/auth/ResetPasswordFirstPage';
import { ResetPasswordPage } from 'pages/auth/ResetPasswordPage';
import { AfterMailSentPage } from 'pages/auth/AfterMailSentPage';
import { FiftyResultPage } from 'pages/FiftyResultPage';
import { FiftyCheckLP } from 'pages/FiftyCheckLP/FiftyCheckLP';
import { DiagnoseUploadPage } from 'pages/FiftyCheckLP/DiagnoseUploadPage';
import { PrefilledRegisterPage } from 'pages/auth/Register/PrefilledRegisterPage';
import { routes } from './publicRoutes';

export const UnauthorizedRouter = () => {
  const router = useRoutes(routes);
  return (
    <>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route
          path="/register-prefilled/:videoId"
          element={<PrefilledRegisterPage />}
        />
        <Route path="/register" element={<RegisterPage />} />

        <Route
          path="/varify/:token/:uid"
          element={<MailAuthenticationPage />}
        />

        <Route path="/relogin" element={<ReLoginPage />} />

        <Route path="/reset_pw_first" element={<ResetPasswordFirstPage />} />

        <Route path="/reset_pw/:token/:uid" element={<ResetPasswordPage />} />

        <Route path="/mail-sent" element={<AfterMailSentPage />} />

        <Route path="/result-share/:videoId" element={<ResultSharePage />} />
        <Route path="/fifty_result/:videoId" element={<FiftyResultPage />} />
        <Route path="/fifty_check" element={<FiftyCheckLP />} />
        <Route path="/diagnose" element={<DiagnoseUploadPage />} />

        <Route path="/introduction" element={<FiftyCheckLP />} />
        <Route path="/" element={<LoginPage />} />
      </Routes>
      <>{router}</>
    </>
  );
};
