// 10級パッケージの6級
type ckptTypeForGrade_4_20kyu =
  | 'よい姿勢'
  | '足を体の前で回す'
  | '足のつき方'
  | 'スピードを出す';

// ここから下をいじる！

// 各級のポイント
export const ckptForGrade_4_20kyu: ckptTypeForGrade_4_20kyu[] = [
  'よい姿勢',
  '足を体の前で回す',
  '足のつき方',
  'スピードを出す',
];

// 合格に必要なもの
export const requirement_4_20kyu: ckptTypeForGrade_4_20kyu[] = [
  'よい姿勢',
  '足を体の前で回す',
];

export type ckptDetails_4_20 =
  | '前後傾'
  | '後ろに蹴っていない'
  | '下に踏む'
  | '足の上がり'
  | '接地様式'
  | 'スピードが出せている'
  //採点用
  | '腰が落ちていない'
  | '腕が地面と平行';

type allCheckpoints_4_20kyu_type = {
  [key in ckptTypeForGrade_4_20kyu]: ckptDetails_4_20[];
};

//星とるためのやつ
export const allCheckPoints_4_20kyu: allCheckpoints_4_20kyu_type = {
  よい姿勢: ['前後傾'],
  足を体の前で回す: ['後ろに蹴っていない', '足の上がり', '下に踏む'],
  足のつき方: ['接地様式'],
  スピードを出す: ['スピードが出せている'],
};

// グッドマークをもらうための要件
export const borderLine_4_20kyu: allCheckpoints_4_20kyu_type = {
  よい姿勢: ['前後傾'],
  足を体の前で回す: ['後ろに蹴っていない', '足の上がり'],
  足のつき方: ['接地様式'],
  スピードを出す: ['スピードが出せている'],
};
