// 10級パッケージの5級
type ckptTypeForGrade_5_20kyu =
  | '頭から足までまっすぐ'
  | 'ベースポジション'
  | 'あしを体の前で入れかえる';

export const ckptForGrade_5_20kyu: ckptTypeForGrade_5_20kyu[] = [
  '頭から足までまっすぐ',
  'ベースポジション',
  'あしを体の前で入れかえる',
];

export const requirement_5_20kyu: ckptTypeForGrade_5_20kyu[] = [
  '頭から足までまっすぐ',
  'ベースポジション',
  'あしを体の前で入れかえる',
];

export type ckptDetails_5_20 =
  | '前後傾'
  | '腰が落ちていない'
  | '上げた足をキープできている'
  | '足の上がり'
  | '足をからだの前でいれかえる'
  | '後ろに蹴っていない'
  | 'うで振り'
  | 'リーチアウト';

type allCheckpoints_5_20kyu_type = {
  [key in ckptTypeForGrade_5_20kyu]: ckptDetails_5_20[];
};

export const allCheckPoints_5_20kyu: allCheckpoints_5_20kyu_type = {
  頭から足までまっすぐ: ['前後傾', '腰が落ちていない'],
  ベースポジション: ['足の上がり', '上げた足をキープできている'],
  あしを体の前で入れかえる: ['足をからだの前でいれかえる'],
};

export const borderLine_5_20kyu: allCheckpoints_5_20kyu_type = {
  頭から足までまっすぐ: ['前後傾'],
  ベースポジション: ['足の上がり'],
  あしを体の前で入れかえる: ['足をからだの前でいれかえる'],
};
