// 10級パッケージの8級
type ckptTypeForGrade_12_20kyu =
  | 'よい姿勢'
  | 'ハイニーができている'
  | 'ベースポジションでとまる';

export const ckptForGrade_12_20kyu: ckptTypeForGrade_12_20kyu[] = [
  'よい姿勢',
  'ハイニーができている',
  'ベースポジションでとまる',
];

export const requirement_12_20kyu: ckptTypeForGrade_12_20kyu[] = [
  'よい姿勢',
  'ハイニーができている',
  'ベースポジションでとまる',
];
