import React from 'react';
import { RunnerType } from 'adapters/repositories/typeDefinition';

import y_sprint from 'static/target_thumbnail/y_sprint.png';
import wall_push from 'static/target_thumbnail/wall_push.png';
import high_knee from 'static/target_thumbnail/high_knee.png';
import thirty from 'static/target_thumbnail/thirty.png';
import cycling from 'static/target_thumbnail/cycling.png';
import three_step from 'static/target_thumbnail/three_step.png';
import ten from 'static/target_thumbnail/ten.png';
import { convertRunnerGrade, is10GradesPackage } from 'components/constants';
import { nonFukushuGrades } from 'components/MenuConst/GoalsForEachGrade/targetsForEachGrade';

export const returnThumbnailForGrade = (runner: RunnerType) => {
  if (is10GradesPackage(runner)) {
    const grade = convertRunnerGrade(runner);
    return <img src={thumbnailListFor10Grades[grade]} alt="" width="99%"></img>;
  }
  const grade = convertRunnerGrade(runner);
  if (!nonFukushuGrades.includes(grade)) {
    return (
      (!!runner.weak_point && (
        <img
          src={thumbnailListFor20Grades[runner.weak_point]}
          alt=""
          width="99%"
        ></img>
      )) || <img src={y_sprint} alt="" width="99%"></img>
    );
  }

  return <img src={thumbnailListFor20Grades[grade]} alt="" width="99%"></img>;
};

const thumbnailListFor10Grades = {
  10: y_sprint, //外
  9: wall_push, //家
  8: high_knee, // 家
  7: thirty, //外
  6: cycling,
  5: three_step,
  4: thirty, // 外
  3: ten, // 外
  2: wall_push, // 家
  1: ten, // 外
  20: undefined, //外
  19: undefined, //家
  18: undefined, // 家
  17: undefined, //外
  16: undefined,
  15: undefined,
  14: undefined, // 外
  13: undefined, // 外
  12: undefined, // 家
  11: undefined, // 外
};

const thumbnailListFor20Grades = {
  20: y_sprint, //外
  19: wall_push, //家
  18: high_knee, // 家
  17: thirty, //外
  16: thirty, //外（マーク走の代わり）
  15: thirty, // 外,ここまで変更
  14: thirty, // 外
  13: ten, // 外
  12: wall_push, // 家
  11: ten, // 外
  10: y_sprint, //外
  9: wall_push, //家
  8: high_knee, // 家
  7: thirty, //外
  6: cycling,
  5: three_step,
  4: thirty, // 外
  3: ten, // 外
  2: wall_push, // 家
  1: ten, // 外
};
