import train_thumb from 'components/MenuConst/thumbnails/train.jpg';
import arm_swing_Walk_thumb from 'components/MenuConst/thumbnails/arm_swing_Walk.jpg';
import towelhighknee_thumb from 'components/MenuConst/thumbnails/towelhighknee.jpg';
import mark_16th_thumb from 'components/MenuConst/thumbnails/mark_16th.jpg';
import touritsu_thumb from 'components/MenuConst/thumbnails/touritsu.jpg';
import { trainVideos, markRunVideos, touritsuVideos } from '../grade_4/videos';
import henkeiDash_thumb from 'components/MenuConst/thumbnails/henkeidash.jpg';

import {
  armSwingWalkVideos,
  henkeiDashVideos,
  towelhighkneeVideos,
} from './videos';

import { menuType } from 'pages/PracticePage/type';

export const arm_swing_Walk_16th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'うでふりウォーク',
  isInside: 'unable',
  target: ['腕ふり'],
  menuVariation: ['up'],
  hitWordList: ['うで', '腕'],
  grade: 4,
  image: arm_swing_Walk_thumb,
  video: armSwingWalkVideos,
};

export const towelhighknee_16th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'タオルももあげ',
  isInside: 'able',
  target: ['足の動き'],
  menuVariation: ['up'],
  hitWordList: ['たおる', 'タオル'],
  grade: 4,
  image: towelhighknee_thumb,
  video: towelhighkneeVideos,
};

export const train: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '列車',
  isInside: 'unable',
  target: ['足の動き'],
  menuVariation: ['tech'],
  hitWordList: ['れっしゃ', '列車'],
  grade: 4,
  image: train_thumb,
  video: trainVideos,
};

export const markRun_16th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'マーク走 ☆重要',
  isInside: 'unable',
  target: ['しせい', '足の動き'],
  menuVariation: ['tech'],
  hitWordList: ['まーく', 'マーク'],
  grade: 4,
  image: mark_16th_thumb,
  video: markRunVideos,
};

export const touritsu: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'ペア倒立',
  isInside: 'able',
  target: ['しせい'],
  menuVariation: ['family'],
  hitWordList: ['倒立', 'さかだち'],
  grade: 4,
  image: touritsu_thumb,
  video: touritsuVideos,
};

export const henkeidash_15th: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: '変形ダッシュ',
  isInside: 'unable',
  target: ['スタート'],
  menuVariation: ['run'],
  hitWordList: ['変形', 'スタート'],
  grade: 5,
  image: henkeiDash_thumb,
  video: henkeiDashVideos,
};
