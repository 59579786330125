// 10級パッケージの7級
type ckptTypeForGrade_17_20kyu =
  | 'しっかり体を倒して走る'
  | '足を前に前に'
  | '腕ふり'
  | '前あしに体重をのせてかまえる'
  | 'いい姿勢で体をたおしてかまえる';

export const ckptForGrade_17_20kyu: ckptTypeForGrade_17_20kyu[] = [
  'しっかり体を倒して走る',
  '足を前に前に',
  '腕ふり',
  '前あしに体重をのせてかまえる',
  'いい姿勢で体をたおしてかまえる',
];

export const requirement_17_20kyu: ckptTypeForGrade_17_20kyu[] = [
  '前あしに体重をのせてかまえる',
  'いい姿勢で体をたおしてかまえる',
  'しっかり体を倒して走る',
];
