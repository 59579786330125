import React from 'react';
import { Route, Routes, useRoutes } from 'react-router-dom';
import { routes } from './authorizedRoutes_v5';
import { VideoUploadPage } from 'pages/UploadPage/VideoUploadPage';
import { ResultPage } from 'pages/ResultPage/index';
import { PracticePage } from 'pages/PracticePage';
import { RegisterPage } from 'pages/auth/Register/RegisterPage';
import { UserAccountPage } from 'pages/MyPage/UserAccountPage';
import { InquiryPage } from 'pages/InquiryPage';
import { TrialUploadPage } from 'pages/TrialUploadPage';
import { LogPage } from 'pages/LogPage/LogPage';
import { MemberHomePage } from 'pages/MemberHome/index';
import { FooterComponent } from 'components/common/PageTemplate';
import { MemberHeader } from 'components/header/MemberHeader';
import { FiftyResultPage } from 'pages/FiftyResultPage';
import { MailAuthenticationPage } from 'pages/auth/MailAuthenticationPage';
import { ReLoginPage } from 'pages/auth/ReLoginPage';
import { ResetPasswordPage } from 'pages/auth/ResetPasswordPage';
import { ChangePasswordPage } from 'pages/auth/ChangePasswordPage';
import { ResultSharePage } from 'pages/ResultPage/ResultSharePage';
import { getAccountInfoAdapter } from 'adapters/getAccountInfoAdapter';
import { AnnouncePage } from 'pages/AnnouncePage';

import { AccountContext } from 'contexts/AccountContext';
import { AuthContext } from 'contexts/AuthContext';

import { AccountType, RunnerType } from 'adapters/repositories/typeDefinition';
import { BossCheckPage } from 'features/scoring/routes/BossCheckPage';

export const AuthorizedRouter = () => {
  const { setAccountInfo, setCurrentRunner, currentRunner } =
    React.useContext(AccountContext);
  const { setIsAuthenticated } = React.useContext(AuthContext);
  const router = useRoutes(routes);

  // isAuthenticatedがTrueになった瞬間にアカウント情報を取りに行く処理を追加
  React.useEffect(() => {
    const curRunner = localStorage.getItem('currentRunner');
    getAccountInfoAdapter()
      .then((accountFromApi: AccountType) => {
        setAccountInfo(accountFromApi);
        // 続いて、localStorageを参照して適切なrunnerをセット
        if (!accountFromApi.runners) {
          return;
        }
        if (curRunner && curRunner.split('').length > 3) {
          // 要は保存されてたら、ということ
          const prevRunner = accountFromApi.runners.find(
            (runner: RunnerType) => runner.id === curRunner,
          );
          // prevRunnerがなかった場合、とりあえず0個目にする。
          console.log('prevRunner=', prevRunner);

          setCurrentRunner(
            (!!prevRunner && prevRunner) || accountFromApi.runners[0],
          );
        } else {
          setCurrentRunner(accountFromApi.runners[0]);
        }
      })
      .catch((_) => {
        // アカウントはあるがランナーがない場合ここに入る
        setIsAuthenticated(false);
        localStorage.setItem('access', 'undefined');
      });
  }, []); // eslint-disable-line
  return (
    <>
      <MemberHeader />
      <main>
        <Routes>
          <Route path="/" element={<MemberHomePage />} />

          <Route path="/login" element={<MemberHomePage />} />
          <Route path="/upload" element={<VideoUploadPage />} />
          <Route
            path="/practice"
            element={<PracticePage runner={currentRunner} />}
          />
          <Route path="/trial-upload" element={<TrialUploadPage />} />
          <Route path="/result/:videoId" element={<ResultPage />} />
          <Route path="/result-share/:videoId" element={<ResultSharePage />} />
          <Route path="/fifty_result/:videoId" element={<FiftyResultPage />} />
          <Route path="/announce" element={<AnnouncePage />} />
          <Route path="/mypage" element={<UserAccountPage />} />
          <Route path="/inquiry" element={<InquiryPage />} />
          <Route path="/logs" element={<LogPage runner={currentRunner} />} />
          <Route path="/boss-check" element={<BossCheckPage />} />
          <Route path="/reset_pw" element={<ResetPasswordPage />} />
          <Route path="/change_pw" element={<ChangePasswordPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route
            path="/varify/:token/:uid"
            element={<MailAuthenticationPage />}
          />
          <Route path="/relogin" element={<ReLoginPage />} />
          <Route path="/" element={<MemberHomePage />} />
        </Routes>
        <>{router}</>
      </main>
      <footer>
        <FooterComponent runner={currentRunner} />
      </footer>
    </>
  );
};
