import React from 'react';
import { useParams } from 'react-router-dom';
import { AccountContext } from 'contexts/AccountContext';

import { BasicInfoKids } from './BasicInfo';
import { VideoNavigator } from './VideoChooseCarousel/VideoNavigator';
import { getVideoByIdAdapter } from 'adapters/getVideosAdapter';
import { VideoType } from 'adapters/repositories/typeDefinition';

interface RouterParams {
  videoId: string;
}

type currentVideoType = VideoType | null;

export const ResultPage = () => {
  const { videoId } = useParams<RouterParams>();
  console.log(videoId);

  const { currentRunner } = React.useContext(AccountContext);

  const [isLoading, setIsLoading] = React.useState(false);

  const [currentVideo, setCurrentVideo] =
    React.useState<currentVideoType>(null);

  const isFirstLogin = videoId === 'first-grade';

  // 動画のIDを元にビデオを持ってくる
  React.useEffect(() => {
    if (isFirstLogin || !videoId) {
      return;
    }
    setIsLoading(true);
    getVideoByIdAdapter(videoId).then((video) => {
      console.log(video);
      setCurrentVideo(video[0]);
      setIsLoading(false);
    });
  }, [videoId]);

  return (
    <div className="App">
      {/* <PageTitle title={'成績表'} /> */}
      <div
        style={{
          marginTop: '6px',
          width: '98%',
          marginLeft: '1.0%',
          textAlign: 'center',
        }}
      >
        <BasicInfoKids
          isFirstLogin={isFirstLogin}
          isLoading={isLoading}
          video={currentVideo}
          runner={currentRunner}
        />
      </div>
      <VideoNavigator videoId={videoId} setCurrentVideo={setCurrentVideo} />
      <div style={{ marginTop: '100px' }}></div>
    </div>
  );
};
