// 10級パッケージの5級
type ckptTypeForGrade_15_20kyu =
  | '頭から足までまっすぐ'
  | 'ベースポジション'
  | 'あしを体の前で入れかえる';

export const ckptForGrade_15_20kyu: ckptTypeForGrade_15_20kyu[] = [
  '頭から足までまっすぐ',
  'ベースポジション',
  'あしを体の前で入れかえる',
];

export const requirement_15_20kyu: ckptTypeForGrade_15_20kyu[] = [
  '頭から足までまっすぐ',
  'ベースポジション',
  'あしを体の前で入れかえる',
];
