import { grades } from 'components/MenuConst/GradeGoalConst';
import { optionsForGrade0_20pkg } from './grade_0';
import { optionsForGrade_1_20pkg } from './grade_1';
import { optionsForGrade_2_20pkg } from './grade_2';
import { optionsForGrade_3_20pkg } from './grade_3';
import { optionsForGrade_4_20pkg } from './grade_4';
import { optionsForGrade_5_20pkg } from './grade_5';
import { optionsForGrade_6_20pkg } from './grade_6';
import { optionsForGrade_7_20pkg } from './grade_7';

type outputType =
  | typeof optionsForGrade0_20pkg
  | typeof optionsForGrade_1_20pkg
  | typeof optionsForGrade_2_20pkg
  | typeof optionsForGrade_3_20pkg
  | typeof optionsForGrade_4_20pkg
  | typeof optionsForGrade_5_20pkg
  | typeof optionsForGrade_6_20pkg
  | typeof optionsForGrade_7_20pkg;

type options_20_type = {
  [key in grades]?: outputType;
};

export const optionsFor20Grades: options_20_type = {
  20: optionsForGrade0_20pkg,
  19: optionsForGrade_1_20pkg,
  18: optionsForGrade_2_20pkg,
  17: optionsForGrade_3_20pkg,
  16: optionsForGrade_4_20pkg,
  15: optionsForGrade_5_20pkg,
  14: optionsForGrade_6_20pkg,
  13: optionsForGrade_7_20pkg,
};
