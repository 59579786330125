import React, { FC, memo } from 'react';
import classes from './gradeSheet.module.css';
import {
  calcObject,
  convertRunnerGrade,
  MAX_GRADE,
} from 'components/constants';
import { Grid } from '@material-ui/core';
import medal from 'static/materials/medal.png';
import { onboardType } from './GuidanceTools/type';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import { returnThumbnailForGrade } from '../func/returnThumbnailForTheGrade';
import undefinedThumbnail from 'static/target_thumbnail/undefinedThumbnail.png';
import { calcTarget } from 'components/MenuConst/GoalsForEachGrade/targetsForEachGrade';

type Props = {
  onboardState: onboardType;
  runner: RunnerType;
};

export const GradeSheet: FC<Props> = memo(({ onboardState, runner }) => {
  const maxGrade = MAX_GRADE;
  const currentGrade = convertRunnerGrade(runner);
  const thumbnailForTheGrade = returnThumbnailForGrade(runner);
  if (onboardState === 'beforeRead') {
    const latestGrade =
      (runner.latest_exam_video.length > 0 &&
        runner.latest_exam_video[0].grade_before) ||
      0;
    return (
      <div>
        <div className={classes.sheet}>
          <div className={classes.upperSection}>
            <Grid container>
              <Grid item xs={3}>
                <img src={undefinedThumbnail} alt="" width="99%"></img>
              </Grid>
              <Grid item xs={9}>
                <div style={{ margin: '8px 0 0 4px', textAlign: 'left' }}>
                  <div
                    style={{
                      fontSize: '10pt',
                      fontWeight: 'bold',
                      lineHeight: '10vw',
                    }}
                  >
                    ???級の目標： ???
                  </div>
                  <div className={classes.horizontalLine}></div>
                  <div
                    style={{
                      fontSize: '10pt',
                      fontWeight: 'bold',
                      lineHeight: '10vw',
                    }}
                  >
                    ねらい： ???
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {
              // eslint-disable-next-line
              [...Array(maxGrade)].map((_, g) => {
                if (g < latestGrade) {
                  return (
                    <div className={classes.completedGrade}>
                      <img src={medal} alt="" width="100%"></img>
                    </div>
                  );
                }
                if (g === latestGrade) {
                  return (
                    <div className={classes.currentGrade}>
                      {String(maxGrade - g)}
                    </div>
                  );
                }
                return (
                  <div className={classes.undoneGrade}>
                    {String(maxGrade - g)}
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className={classes.sheet}>
        <div className={classes.upperSection}>
          <Grid container>
            <Grid item xs={3}>
              {thumbnailForTheGrade}
            </Grid>
            <Grid item xs={9}>
              <div style={{ margin: '8px 0 0 4px', textAlign: 'left' }}>
                <div
                  style={{
                    fontSize: '10pt',
                    fontWeight: 'bold',
                    lineHeight: '10vw',
                  }}
                >
                  {(currentGrade !== '5' && currentGrade + '級') || 'いま'}
                  の目標：
                  {calcTarget(runner)}
                </div>
                <div className={classes.horizontalLine}></div>
                <div
                  style={{
                    fontSize: '10pt',
                    fontWeight: 'bold',
                    lineHeight: '10vw',
                  }}
                >
                  ねらい：
                  {calcObject(runner)}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        {runner.grade > 0 && (
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {
              // eslint-disable-next-line
              [...Array(maxGrade)].map((_, g) => {
                if (g < runner.grade) {
                  return (
                    <div className={classes.completedGrade}>
                      <img src={medal} alt="" width="100%"></img>
                    </div>
                  );
                }
                if (g === runner.grade) {
                  return (
                    <div className={classes.currentGrade}>
                      {String(maxGrade - g)}
                    </div>
                  );
                }
                return (
                  <div className={classes.undoneGrade}>
                    {String(maxGrade - g)}
                  </div>
                );
              })
            }
          </div>
        )}
        {runner.grade === 0 && <div style={{ fontSize: '6px' }}>&nbsp;</div>}
      </div>
    </div>
  );
});
