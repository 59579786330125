// 10級パッケージの8級
type ckptTypeForGrade_18_20kyu =
  | 'よい姿勢'
  | '足の上がり'
  | '空中であしを入れかえる'
  | '体をたおせているか';

export const ckptForGrade_18_20kyu: ckptTypeForGrade_18_20kyu[] = [
  'よい姿勢',
  '足の上がり',
  '空中であしを入れかえる',
  '体をたおせているか',
];

export const requirement_18_20kyu: ckptTypeForGrade_18_20kyu[] = [
  'よい姿勢',
  '足の上がり',
  '空中であしを入れかえる',
];
