import { RunnerType } from 'adapters/repositories/typeDefinition';
import { convertRunnerGrade, is10GradesPackage } from 'components/constants';
import { grades10, grades } from '../GradeGoalConst';

export const decideMessageVideoLink = (runner: RunnerType) => {
  if (is10GradesPackage(runner)) {
    const runnerGrade = convertRunnerGrade(runner) as grades10;
    return introVideoDictFor10Grades[runnerGrade];
  }
  const runnerGrade = convertRunnerGrade(runner) as grades;
  return introVideoDictFor20Grades[runnerGrade];
};

type sampleVideoDict10Type = {
  [key in grades10]: string;
};

export const introVideoDictFor10Grades: sampleVideoDict10Type = {
  10: 'https://www.youtube.com/embed/9a68R5AjZyk',
  9: 'https://www.youtube.com/embed/BDGKhOTLb6c',
  8: 'https://www.youtube.com/embed/2rYT4Tt3Gvk',
  7: 'https://www.youtube.com/embed/KDRUp0TnCBI',
  6: 'https://www.youtube.com/embed/6of0Mt6Q53s',
  5: 'https://www.youtube.com/embed/6Gc0mYgVfwY',
  4: 'https://www.youtube.com/embed/nZppBE08D0o',
  3: 'https://www.youtube.com/embed/LP45AVeINE8',
  2: 'https://www.youtube.com/embed/seMKF5NZbJk',
  1: 'https://www.youtube.com/embed/aHFYkpRmMTo',
};

type sampleVideoDictType = {
  [key in grades]: string;
};

export const introVideoDictFor20Grades: sampleVideoDictType = {
  20: 'https://www.youtube.com/embed/Mxor_tTRzl0',
  19: 'https://www.youtube.com/embed/Q_mrWndGaRk',
  18: 'https://www.youtube.com/embed/WV98b-1XAV0',
  17: 'https://www.youtube.com/embed/YriFD4qJvmM',
  16: 'https://www.youtube.com/embed/6-GywvC9NUI',
  15: 'https://www.youtube.com/embed/NnB53c5rDsc',
  14: '',
  13: '',
  12: '',
  11: 'https://www.youtube.com/embed/sZ76gFmG5kw',
  10: 'https://www.youtube.com/embed/sZ76gFmG5kw',
  9: 'https://www.youtube.com/embed/RwOlXPLX0O8',
  8: 'https://www.youtube.com/embed/ctJ6sFe9rjg',
  7: 'https://www.youtube.com/embed/F5G0l_GqKkc',
  6: 'https://www.youtube.com/embed/2kWvq8aO5Co',
  5: 'https://www.youtube.com/embed/hZ3S_9bIKCg',
  4: 'https://www.youtube.com/embed/Cta-XP0f0xE',
  3: 'https://www.youtube.com/embed/1BqgOS35y-M',
  2: 'https://www.youtube.com/embed/1jV1BqnPCyk',
  1: 'https://www.youtube.com/embed/Vn70HQtQAmY',
};
