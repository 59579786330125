import React, { FC, memo } from 'react';
import classes from './guidanceTools.module.css';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { onboardType } from './type';
import { usePopover } from 'hooks/usePopover';
import { DumbbellPopover } from './popovers/DumbbellPopover';
import { RunnerType } from 'adapters/repositories/typeDefinition';

type Props = {
  onboardState: onboardType;
  runner: RunnerType | undefined;
};
export const Dumbbell: FC<Props> = memo(({ onboardState, runner }) => {
  const innerComponent = DumbbellPopover(onboardState, runner);
  const { handleClick, CustomPopover } = usePopover(innerComponent);

  if (onboardState === 'beforeMsg') {
    return (
      <div className={classes.dumbbellPosition}>
        <div className={classes.disabled}>
          <div className={classes.iconContent} onClick={handleClick}>
            <FitnessCenterIcon style={{ fontSize: '14vw', color: '#a9a9a9' }} />
          </div>
          {CustomPopover}
        </div>
      </div>
    );
  }

  if (onboardState === 'beforePractice') {
    return (
      <div className={classes.dumbbellPosition}>
        <div style={{ display: 'flex' }}>
          <div className={classes.shouldBeTouched}>
            <div className={classes.iconLarge} onClick={handleClick}>
              <FitnessCenterIcon
                style={{ fontSize: '18vw', color: '#a9a9a9' }}
              />
            </div>
          </div>
          <div className={classes.dumbellExplanation}>なにかな？</div>
        </div>

        {CustomPopover}
      </div>
    );
  }

  return (
    <div className={classes.dumbbellPosition}>
      <div className={classes.canTouch}>
        <div className={classes.iconContent} onClick={handleClick}>
          <FitnessCenterIcon style={{ fontSize: '16vw', color: '#a9a9a9' }} />
        </div>
      </div>
      {CustomPopover}
    </div>
  );
});
