import { RunnerType } from 'adapters/repositories/typeDefinition';
import React from 'react';
import classes from '../guidanceTools.module.css';
import { onboardType } from '../type';

export const DumbbellPopover = (
  onboardState: onboardType,
  runner: RunnerType | undefined,
) => {
  if (onboardState === 'beforePractice') {
    return (
      <div className={classes.popover}>
        宝箱を開けたらさっそく走ってみよう！がめん下の「トレーニング」からかけっこ診断のやり方を見て、じっさいにやってみよう！
      </div>
    );
  }
  if (onboardState === 'beforeMsg') {
    return (
      <div className={classes.popover}>
        宝箱はあけたかな？
        <br />
        練習の前にはぜひ先生からのメッセージを見ておこう！
      </div>
    );
  }

  // eslint-disable-next-line
  if (!!runner && runner.grade < 1) {
    return (
      <>
        <div className={classes.popover}>
          かけっこ診断の撮影方法を確認する場合は、がめん下の「トレーニング」から確認できるよ！
        </div>
      </>
    );
  }

  return (
    <>
      <div className={classes.popover}>
        練習をしたい場合は、がめん下の「トレーニング」からおすすめの練習を見てみよう！
      </div>
    </>
  );
};
