import React from 'react';
import { RouteObject } from 'react-router-dom';
import { AuthRoutes } from 'features/auth/routes';

export const routes: RouteObject[] = [
  {
    path: '/auth/*',
    element: <AuthRoutes />,
  },
];
