import React, { FC } from 'react';

import { Card } from '@material-ui/core';

export const AnnouncePage = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <>
        <Card style={{ fontSize: '12pt', padding: '6px' }}>
          <div style={{ textAlign: 'left', padding: '10px' }}>
            この度はハイブリッドかけっこ教室をご受講頂き誠にありがとうございます！
            ご利用前に下記をご一読頂けますと幸いです。
          </div>
        </Card>
        <div style={{ marginTop: '10px' }}></div>
        <Card
          style={{
            fontSize: '12pt',
            padding: '6px',
            backgroundColor: '#e6ffe9',
            color: '#424242',
          }}
        >
          <div style={{ textAlign: 'left', padding: '10px' }}>
            <ToDos title="公式LINEアカウントの登録" />
            <div style={{ marginLeft: '30px', marginTop: '6px' }}>
              皆様には
              <a href="https://lin.ee/OhF30wQ">公式LINE</a>
              の追加をお願いしております。連絡やお問い合わせの際の窓口となりますのでご登録をお願いいたします。
            </div>
          </div>
          <div style={{ textAlign: 'left', padding: '10px' }}>
            <ToDos title="教室のすすめ方" />
            <div style={{ marginLeft: '30px', marginTop: '6px' }}>
              <div style={{ marginTop: '8px', color: '#000000' }}>
                1. レッスンを受ける（金曜日） <br /> 2. 宿題に取り組む <br />
                3. 昇級テスト用動画の撮影 + 送信 <br /> 4. 宿題の添削結果を確認
              </div>
            </div>
            <div style={{ marginLeft: '30px', marginTop: '6px' }}></div>
          </div>
        </Card>
        <div style={{ marginTop: '70px' }}>
          <br />
        </div>
      </>
    </div>
  );
};

type Props = {
  title: string;
};
const ToDos: FC<Props> = ({ title }) => {
  return (
    <div style={{ display: 'flex' }}>
      <div
        style={{
          marginRight: '10px',
          color: '#005500',
        }}
      >
        ✅
      </div>
      <div style={{ fontWeight: 'bold' }}>{title}</div>
    </div>
  );
};
