import { AddRunnerPage } from 'features/auth/routes/AddRunnerPage';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { LoginPage } from './LoginPage';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
      <Route path="add-runner" element={<AddRunnerPage />} />
    </Routes>
  );
};
