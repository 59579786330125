import { RunnerType } from 'adapters/repositories/typeDefinition';
import axios from 'axios';

export const SERVER_HOST = 'https://100.26.159.14:5000';
export const LOCAL_HOST = 'http://127.0.0.1:8000/api/v1';
export const TEST_SERVER_HOST = 'https://3.236.168.212:8000/api/v1';
export const TEST_FRONT_DOMAIN = "https://test.d3c7spy4c3mn0o.amplifyapp.com/"
// const awsURL = 'https://d1z7ngaqpzcnk0.cloudfront.net'; // 本番用
// const midatenTest = 'https://d1v2ptoqventyy.cloudfront.net/api/v1';
const awsTestBack = 'https://dqh5k6grrmywd.cloudfront.net/api/v1';
const nasBack = 'https://dmhwb5abo70xa.cloudfront.net/api/v1';
// const idatenKidsBack = 'https://d32chqqoouer0f.cloudfront.net/api/v1';
// const midatenDps = "https://d3jr5cw5hhzxmq.cloudfront.net/api/v1"

export const HOST = process.env.REACT_APP_BACKEND || awsTestBack;
// export const HOST = nasBack

export const getInfo = (videoPath: string) => axios.get(`${HOST}/load_json/${videoPath}`);

export const postLinkVideos = (email:string) => axios.post(`${HOST}/link-videos/`, {
    email: email
});

export const sendRunnerRegister = (params: any) => axios.post(`${HOST}/register-runner/`, params, {
    headers: {
        'Content-Type': 'application/json',
        Authorization: setTokenToHeader(),
    },
});

export const postPasswordChange = (params: any) => axios.post(`${HOST}/auth/users/set_password/`, params, {
    headers: {
        'Content-Type': 'application/json',
        Authorization: setTokenToHeader(),
    },
});

export const postResetPassword = (params: any) => axios.post(`${HOST}/auth/users/reset_password_confirm/`, params);

export const patchSelectRunners = (accountId:string, params: any) => axios.patch(`${HOST}/select-paid-runners/${accountId}`, params);

export const postResetPasswordInitiate = (email: any) => axios.post(`${HOST}/auth/users/reset_password/`, { email: email });

export const postVideo = (params: any) => axios.post(`${HOST}/post-videos/`, params, {
    headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: setTokenToHeader(),
    },
});

export const replaceVideo = (params: any) => axios.post(`${HOST}/replace-videos/`, params, {
    headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: setTokenToHeader(),
    },
});

export const getVideoByRunner = (runnerId: string) => axios.get(`${HOST}/videos-by-runner/${runnerId}`, {
    headers: {
        Authorization: setTokenToHeader(),
    },
});

export const getAllVideo = () =>
    axios.get(`${HOST}/all-videos/`, {
        headers: {
            Authorization: setTokenToHeader(),
        },
    });

export const getAllVideoByPage = (limit:number, offset:number) =>
    axios.get(`${HOST}/all-videos-page/?limit=${limit}&offset=${offset}`, {
        headers: {
            Authorization: setTokenToHeader(),
        },
    });

export const getExamVideosByPage = (limit:number, offset:number) =>
    axios.get(`${HOST}/all-videos-page/?is-only-exam=true&limit=${limit}&offset=${offset}`, {
        headers: {
            Authorization: setTokenToHeader(),
        },
    });

export const getVideoById = (videoId: string) => axios.get(`${HOST}/video-info/${videoId}`, {
    headers: {
        Authorization: setTokenToHeader(),
    },
});

export const getVideoForShare = (videoId: string) => axios.get(`${HOST}/video-info-share/${videoId}`);

export const getUsersGrade = () =>
    axios.get(`${HOST}/get-runners-grade/`, {
        headers: {
            Authorization: setTokenToHeader(),
        },
    });

export const getAllRunnersByCoach = () =>
    axios.get(`${HOST}/all-runners/`, {
        headers: {
            Authorization: setTokenToHeader(),
        },
    });

export const getAllVideosWithUser = () =>
    axios.get(`${HOST}/all-videos-abbreviate/`, {
        headers: {
            Authorization: setTokenToHeader(),
        },
    });

export const deleteVideoById = (videoId: string) => axios.delete(`${HOST}/video-info/${videoId}`, {
    headers: {
        Authorization: setTokenToHeader(),
    },
});

export const getAccountInfo = () =>
    axios.get(`${HOST}/myinfo/`, {
        headers: {
            Authorization: setTokenToHeader(),
        },
    });

export const putAccountInfo = (params: any) => axios.put(`${HOST}/myinfo/`, params, {
    headers: {
        'Content-Type': 'application/json',
        Authorization: setTokenToHeader(),
    },
});

type putRunnerInfoParams = {
    [key in keyof RunnerType]?:string
}
export const putRunnerInfo = (params: putRunnerInfoParams) => axios.put(`${HOST}/runner-info/${params.id}`, params, {
    headers: {
        'Content-Type': 'application/json',
        Authorization: setTokenToHeader(),
    },
});

export const postManualScore = (params: any) => axios.post(`${HOST}/score-manual/`, params, {
    headers: {
        'Content-Type': 'application/json',
        Authorization: setTokenToHeader(),
    },
});

export const getVideoByParttime = (parttimeId: string) => axios.get(`${HOST}/video-by-parttime/${parttimeId}`, {
    headers: {
        Authorization: setTokenToHeader(),
    },
});

export const postDauByUser = (runnerId:string) =>
    axios.get(`${HOST}/runner-dau/${runnerId}`, {
        headers: {
            Authorization: setTokenToHeader(),
        },
    });

export function setTokenToHeader() {
    const authToken = localStorage.getItem('access');
    if (authToken !== 'undefined') {
        return 'JWT ' + authToken;
    }
    return ''; //FIXME: なかった場合のforceリダイレクトがあった方が良い
}
