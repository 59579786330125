import React, { FC } from 'react';
import { VideoType } from 'adapters/repositories/typeDefinition';

type Props = {
  video: VideoType;
};

export const VideoLabelForUser: FC<Props> = ({ video }) => {
  if (
    (!!video.video_type && video.video_type === 'trial') ||
    video.video_type === 'diagnose'
  ) {
    const nowDate = new Date();
    const estDate =
      (!!video.estimated_complete_date &&
        new Date(video.estimated_complete_date)) ||
      new Date();
    const diffMin = (estDate.getTime() - nowDate.getTime()) / 1000 / 60;

    return (
      <>
        {video.video_type === 'diagnose' && (
          <div
            style={{
              fontWeight: 'bold',
              backgroundColor: '#87cefa',
              color: 'white',
              padding: '2px',
              fontSize: '8pt',
              borderRadius: '10px',
            }}
          >
            診断
          </div>
        )}
        <div>
          {(!!video.score && !!video.score.speed && (
            <span style={{ fontSize: '8pt', fontWeight: 'bold' }}>完了！</span>
          )) ||
            (diffMin > 0 && (
              <span style={{ fontSize: '8pt' }}>
                あと約{Math.ceil(diffMin) + 1}分
              </span>
            )) || <span style={{ fontSize: '8pt' }}>あと 0 分</span>}
        </div>
      </>
    );
  }

  if (!!video.no_account_info) {
    return (
      <>
        <div
          style={{
            fontWeight: 'bold',
            backgroundColor: '#32cd32',
            color: 'white',
            fontSize: '7pt',
            borderRadius: '10px',
            padding: '1px',
          }}
        >
          診断完了
        </div>
      </>
    );
  }

  return (
    <>
      {video.checked_by_boss ? (
        <div
          style={{
            fontWeight: 'bold',
            backgroundColor: '#32cd32',
            color: 'white',
            fontSize: '8pt',
            padding: '2px',
            borderRadius: '10px',
          }}
        >
          添削完了
        </div>
      ) : (
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '8pt',
            borderRadius: '10px',
            padding: '2px',
            backgroundColor: '#CCCCCC',
          }}
        >
          添削待ち
        </div>
      )}
    </>
  );
};
