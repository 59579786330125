import axios from 'axios';
import { HOST } from 'v1apiRequest';
import { AuthApiInfo } from '../types';

export const sendRegisterInfo = (params: AuthApiInfo) =>
  axios.post(`${HOST}/register/`, {
    email: params.email,
    password: params.password,
    re_password: params.confirmPassword,
    username: params.userName,
    gender: params.gender,
    birthday: params.birthday,
    height: params.height,
    app: params.app,
    app_version: params.app_version,
    app_domain: params.domain,
    student_group: decideStudentGroup(),
  });

export const postDjoserRegister = (params: AuthApiInfo) =>
  axios.post(`${HOST}/auth/users/`, {
    email: params.email,
    password: params.password,
    re_password: params.confirmPassword,
    username: params.userName,
    gender: params.gender,
    height: params.height,
    app: params.app,
    app_version: params.app_version,
    app_domain: params.domain,
  });

function decideStudentGroup(): string {
  switch (process.env.REACT_APP_FRONT_DOMAIN) {
    case 'https://www.idaten-nas.com/':
      return 'nas';
    case 'https://www.idaten-app.com/':
      return 'dps';
    case 'https://www.idaten-kids.com/':
      return 'ai';
    default:
      return 'ai';
  }
}
