import basePositinJump_thumb from 'components/MenuConst/thumbnails/basePositinJump.jpg';
import baseWalk from 'components/MenuConst/thumbnails/baseWalk.png';
import kabeoshi_thumb from 'components/MenuConst/thumbnails/kabeoshi.png';
import yHighkneeSprint_thumb from 'components/MenuConst/thumbnails/yHighkneeSprint.jpg';
import armSwing from 'components/MenuConst/thumbnails/arm_swing.png';
import darumasan_thumb from 'components/MenuConst/thumbnails/darumasan_thumb.jpg';
import singleLegRotation_thumb from 'components/MenuConst/thumbnails/singleLegRotation.jpg';
import threeHighKnee_thumb from 'components/MenuConst/thumbnails/threeHighKnee_thumb.jpg';
import stepQuick_thumb from 'components/MenuConst/thumbnails/stepQuick.jpg';
import highknee_dash_thumb from 'components/MenuConst/thumbnails/highknee_dash.jpg';

import {
  re_basePositinJumpVideos,
  basePositinWalkVideos,
  kabeoshiBasicVideos,
  yHighkneeSprintVideos,
  singleLegRotationVideos,
} from '../grade_1/videos';

import {
  darumasanVideos,
  threeHighKneeVideos,
  stepQuickVideo,
  highKneeDashVideo,
} from './videos';

import { armSwingVideos } from '../grade_0/videos';

import { menuType } from 'pages/PracticePage/type';

export const re_basePositinJump: menuType = {
  duration: 5, // 所要時間（分）を入れる
  title: 'ベースポジションジャンプ',
  isInside: 'able',
  target: ['しせい', '足の動き'],
  menuVariation: ['up'],
  hitWordList: [
    'べーす',
    'ベース',
    'ジャンプ',
    'じゃんぷ',
    'いえ',
    '家',
    'おうち',
  ],
  grade: 0,
  image: basePositinJump_thumb,
  video: re_basePositinJumpVideos,
};

export const basePositionWalk_19th: menuType = {
  title: 'ベースポジションウォーク',
  isInside: 'able',
  target: ['しせい', '足の動き'],
  grade: 1,
  duration: 10,
  menuVariation: ['up'],
  hitWordList: [
    'ベース',
    'べーす',
    'うぉーく',
    'ウォーク',
    'Y字',
    'ワイ',
    'Y',
    'もも',
    'じゃんぷ',
    'ジャンプ',
    'いえ',
    '家',
    'おうち',
  ],
  image: baseWalk,
  video: basePositinWalkVideos,
};

export const kabeoshiBasic: menuType = {
  grade: 1,
  duration: 15,
  title: '壁押し ☆重要',
  target: ['足の動き'],
  menuVariation: ['tech'],
  isInside: 'able',
  hitWordList: ['Y字', 'ワイ', 'Y', 'もも'],
  image: kabeoshi_thumb,
  video: kabeoshiBasicVideos,
};

export const threeHighKnee: menuType = {
  grade: 2,
  isInside: 'able',
  duration: 15,
  target: ['足の動き'],
  title: '3歩ハイニー ☆重要',
  menuVariation: ['tech'],
  hitWordList: ['はいにー', 'ハイニー', '3', 'さんほ', 'いえ', '家', 'おうち'],
  image: threeHighKnee_thumb,
  video: threeHighKneeVideos,
};

export const arm_swing_19th: menuType = {
  duration: 5,
  title: 'ペットボトルうでふり',
  target: ['腕ふり'],
  isInside: 'able',
  menuVariation: ['family'],
  hitWordList: ['うで', '腕', 'ふり', '振り', 'いえ', '家', 'おうち'],
  grade: 0,
  image: armSwing,
  video: armSwingVideos,
};

export const yHighkneeSprint: menuType = {
  grade: 1,
  isInside: 'unable',
  duration: 15,
  title: 'Y字ももあげスプリント',
  target: ['しせい', '足の動き'],
  menuVariation: ['run'],
  hitWordList: ['かべ', '壁', 'おし', '押し', 'いえ', '家', 'おうち'],
  image: yHighkneeSprint_thumb,
  video: yHighkneeSprintVideos,
};

export const darumasan: menuType = {
  duration: 10,
  title: 'だるまさんがころんだハイニー',
  menuVariation: ['tech'],
  target: ['しせい', '足の動き'],
  isInside: 'able',
  grade: 2,
  hitWordList: [
    'はいにー',
    'ハイニー',
    'だるまさん',
    'ダルマ',
    'いえ',
    '家',
    'おうち',
  ],
  image: darumasan_thumb,
  video: darumasanVideos,
};

export const singleLegRotation: menuType = {
  grade: 1,
  duration: 15,
  title: 'かたあし回転ジャンプ',
  target: ['しせい'],
  isInside: 'able',
  menuVariation: ['family'],
  hitWordList: [
    'かた',
    'あし',
    '片',
    '足',
    'かいてん',
    '回転',
    'じゃんぷ',
    'ジャンプ',
    'いえ',
    '家',
    'おうち',
  ],
  image: singleLegRotation_thumb,
  video: singleLegRotationVideos,
};

export const stepQuick: menuType = {
  grade: 2,
  duration: 10,
  target: ['腕ふり', '全力走'],
  title: '階段クイック',
  isInside: 'able',
  hitWordList: [' かいだん', '階段'],
  menuVariation: ['run'],
  image: stepQuick_thumb,
  video: stepQuickVideo,
};

export const highKneeDash: menuType = {
  grade: 2,
  duration: 10,
  isInside: 'unable',
  title: 'たくさん走ろう！',
  hitWordList: ['ももあげ', 'だっしゅ', 'ダッシュ', 'ハイニー'],
  menuVariation: ['run'],
  image: highknee_dash_thumb,
  target: ['足の動き', '全力走'],
  video: highKneeDashVideo,
};
