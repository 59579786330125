import React, { FC } from 'react';

import '../../components/components.css';
import loadingGif from '../../static/running-101-gif.gif';
import { Card, Grid } from '@material-ui/core';
import CampaignIcon from '@mui/icons-material/Campaign';
import { RunnerType, VideoType } from 'adapters/repositories/typeDefinition';
import { VideoPlayer } from './components/VideoPlayer';
import { FirstLoginView } from './components/FirstLoginView';
// eslint-disable-next-line
import { DiagnoseViewer } from './components/DiagnoseViewer';

import { SelfCheckTable } from './components/SelfCheckTable';

type Props = {
  isFirstLogin: boolean;
  isLoading: boolean;
  runner: RunnerType | undefined;
  video: VideoType | null;
};

export const BasicInfoKids: FC<Props> = ({
  isFirstLogin,
  isLoading,
  runner,
  video,
}) => {
  if (isFirstLogin) {
    return <FirstLoginView />;
  }

  if (!!isLoading) {
    return (
      <div>
        ビデオをロード中
        <img src={loadingGif} height="150" width="auto" alt="" />
      </div>
    );
  }

  if (!video) {
    return <div>ビデオが見つかりません</div>;
  }

  if (!!video?.no_account_info || video?.video_type === 'diagnose') {
    return (
      <div>{!!runner && <DiagnoseViewer video={video} runner={runner} />}</div>
    );
  }

  if (!!video.id && !video.is_exam) {
    return (
      <div>
        <VideoPlayer isLoading={isLoading} videoInfo={video} />
        <br />
        <SelfCheckTable video={video} runner={runner} />
      </div>
    );
  }

  return <div></div>;
};

export const Excuse = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <Card style={{ padding: '10px', width: '95%' }}>
        <Grid container>
          <Grid item xs={1} style={{ marginTop: '20px' }}>
            <CampaignIcon style={{ color: '#ffa500' }} />
          </Grid>
          <Grid item xs={11}>
            <div
              style={{
                color: '#424242',
                fontSize: '8pt',
                textAlign: 'left',
              }}
            >
              このタイムは動画内の速度から算出した50mタイムの参考値です。実際のタイムは速度だけではなく、スタートが得意なタイプか後半が得意なタイプかによっても大きく変わってきます。あくまでもゲーム感覚で、一つの目安としてお楽しみください！
            </div>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};
