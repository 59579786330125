import React from 'react';
import classes from '../guidanceTools.module.css';
import { buttonStateType } from '../type';
import { Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

export const CrownPopover = (
  buttonState: buttonStateType,
  isFirst: boolean,
) => {
  const navigate = useNavigate();
  if (isFirst) {
    return (
      <div className={classes.popover}>
        昇級テストにいどむ前に、まずは練習だ！きいろいボタンをおしてみよう！
      </div>
    );
  }
  if (buttonState === 'shouldTouch') {
    return (
      <div className={classes.popover}>
        練習はバッチリかな？動画が撮れたらかけっこ診断に進もう！
        <div
          style={{
            margin: '24px 0 12px 0',
            textAlign: 'center',
            width: '100%',
          }}
        >
          <Button
            onClick={() => {
              navigate('/trial-upload');
            }}
            style={{
              width: '60%',
              fontWeight: 'bold',
              fontSize: '12pt',
              backgroundColor: '#0000ff',
              color: 'white',
            }}
            variant="contained"
          >
            {'かけっこ診断へ'}
          </Button>
        </div>
      </div>
    );
  }

  if (buttonState === 'disabled') {
    return (
      <div className={classes.popover}>
        かけっこ診断にすすむ前に、まずは練習だ！きいろいボタンをおしてみよう！
      </div>
    );
  }

  return (
    <div className={classes.popover}>
      かけっこ診断の結果を楽しみに待っててね！
    </div>
  );
};
