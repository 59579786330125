import React, { useState, FC } from 'react';
import axios from 'axios';
import {
  Button,
  CircularProgress,
  Box,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { HOST } from 'v1apiRequest';
import { useNavigate } from 'react-router-dom';
import { RunnerInfoType } from '../types';
import { useSendCompleteModal } from 'hooks/useSendCompleteModal';

type Props = {
  runnerInfo: RunnerInfoType;
  isComplete: boolean;
  failFunc: () => void;
  color: any;
  // eslint-disalbe-next-line
  title?: string;
  file: File | null;
};

type videoInfoType = {
  filePath: string;
  finishTime: string;
};

const defaultInfo = {
  filePath: '',
  finishTime: '00:00',
};

export const UploadButton: FC<Props> = ({
  runnerInfo,
  isComplete,
  failFunc,
  color,
  title = '動画を送信',
  file,
}) => {
  const [progress, setProgress] = useState(0.0);
  const [isUploading, setIsUploading] = useState(false);
  const [videoInfo, setVideoInfo] = useState<videoInfoType>(defaultInfo);
  const { forceModalOpen, SendCompleteModal } = useSendCompleteModal(videoInfo);
  const navigate = useNavigate();

  const completeFunc = (data: any) => {
    forceModalOpen(true);
    setVideoInfo({
      filePath: data.id,
      finishTime: data.estimated_complete_date,
    });
    console.log('res=', data);
  };

  const sendVideo = () => {
    if (!file) return;
    const params = new FormData();
    console.log('domain=', process.env.REACT_APP_FRONT_DOMAIN);

    params.append('content', file);
    params.append('email', runnerInfo.email);
    params.append('height', String(runnerInfo.height));
    params.append(
      'info',
      JSON.stringify({
        username: runnerInfo.username,
        gender: runnerInfo.gender,
        domain:
          (!!process.env.REACT_APP_FRONT_DOMAIN &&
            process.env.REACT_APP_FRONT_DOMAIN) ||
          null,
      }),
    );
    params.append('cloudfront_url', HOST.split('/api/')[0]);
    console.log('push');
    setIsUploading(true);
    postVideoWithProgress(
      params,
      setProgress,
      completeFunc,
      failFunc,
      setIsUploading,
    );
  };

  if (isComplete) {
    return (
      <div style={{ marginTop: '20px' }}>
        {isUploading ? (
          <>
            送信中 <CircularProgress size={20} />
            <div>
              <LinearProgressWithLabel progress={progress} />
            </div>
          </>
        ) : (
          <>
            {(!videoInfo.filePath && (
              <Button variant="contained" color={color} onClick={sendVideo}>
                {title}
              </Button>
            )) || <>{SendCompleteModal}</>}
          </>
        )}
      </div>
    );
  }
  return (
    <>
      <Button variant="contained" disabled>
        {title}
      </Button>
    </>
  );
};

async function postVideoWithProgress(
  params: any,
  onProgressFunc: any,
  completeFunc: any,
  failFunc: any,
  setIsUploading: React.Dispatch<React.SetStateAction<boolean>>,
) {
  const config = {
    onUploadProgress: function (progressEvent: any) {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 99) / progressEvent.total,
      );
      onProgressFunc(percentCompleted);
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };
  console.log(params);

  axios
    .post(`${HOST}/post-videos-trial-any/`, params, config)
    .then((res) => {
      console.log('OK');
      completeFunc(res.data);
      setIsUploading(false);
    })
    .catch((res) => {
      console.log('fail');
      failFunc();
      setIsUploading(false);
    });
}

const LinearProgressWithLabel = ({ progress }: any) => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Box minWidth={35}>
        <Typography
          variant="body2"
          color="textSecondary"
        >{`${progress}%`}</Typography>
      </Box>
    </Box>
  );
};
