import { ckptForGrade_0_20kyu } from './GoalsForEachGrade/CheckpointsFor20Pkg/grade_0';
import { ckptForGrade_1_20kyu } from './GoalsForEachGrade/CheckpointsFor20Pkg/grade_1';
import { ckptForGrade_2_20kyu } from './GoalsForEachGrade/CheckpointsFor20Pkg/grade_2';
import { ckptForGrade_3_20kyu } from './GoalsForEachGrade/CheckpointsFor20Pkg/grade_3';
import { ckptForGrade_4_20kyu } from './GoalsForEachGrade/CheckpointsFor20Pkg/grade_4';

// 孫用の言葉（Deep）
export type checkPointListJA =
  | 'eval'
  | 'いい姿勢'
  | 'ポンポン感'
  | '接地'
  | 'うで振り'
  | '腕が体と平行である'
  | 'スピードが出せている'
  | '前後傾'
  | '腕が上を向いている'
  | '腰が落ちていない'
  | '足が最低限上がっている'
  | '接地様式'
  | '倒れ込み角度'
  | '腰の引け具合'
  | 'かかとが浮いているか'
  | '前に進んでいるか'
  | '足の上がり'
  | '後ろへの蹴り上げ'
  | '腕が地面と平行'
  | 'リーチアウト'
  | '膝のつぶれ'
  | 'リズム'
  | '股関節から動かせている'
  | 'ベースポジションで止まる'
  | 'ハイニーができている'
  | '後ろに蹴っていない'
  | '下に踏む'
  | '上げた足をキープできている'
  | '足をからだの前でいれかえる'
  | '前さばき'
  | '構えで前脚に体重がのっている'
  | '構えで上体角度が45度程度'
  | '構えで横から見て上体と後ろ足がまっすぐ'
  | '走りで6歩以上体を倒せている'
  | '足を前に前に持ってくる'
  | 'うでを前に前に振る'
  | '足を空中で入れかえる'
  | '六歩以上体を倒せている'
  | '足の流れ'
  | '足を壁押しのように動かす'
  | 'scoredGrade';

export const ckptShallowAll = [
  ...ckptForGrade_0_20kyu,
  ...ckptForGrade_1_20kyu,
  ...ckptForGrade_2_20kyu,
  ...ckptForGrade_3_20kyu,
  ...ckptForGrade_4_20kyu,
];

export type ckptShallow = typeof ckptShallowAll[number];

export type ScoreEditType = {
  [key in checkPointListJA]?: string | grades;
};

export type grades5 = '5' | '4' | '3' | '2' | '1';

export type grades10 =
  | '10'
  | '9'
  | '8'
  | '7'
  | '6'
  | '5'
  | '4'
  | '3'
  | '2'
  | '1';

export type grades =
  | '20'
  | '19'
  | '18'
  | '17'
  | '16'
  | '15'
  | '14'
  | '13'
  | '12'
  | '11'
  | '10'
  | '9'
  | '8'
  | '7'
  | '6'
  | '5'
  | '4'
  | '3'
  | '2'
  | '1';
type numberGradeConverterType = (num: number) => grades;
export const numberGradeConverter: numberGradeConverterType = (num) => {
  if (num < 0 || num > 9) {
    return '10' as grades;
  }
  return String(10 - num) as grades;
};
export const gradeGoalDict = {
  10: ['Y字スプリントを習得せよ！'], //外
  9: ['壁押しドリルを習得せよ！'], //家
  8: ['3歩ハイニーを習得せよ！'], // 家
  7: ['足を体の前で回す！'], //外
  6: ['自転車ドリルを習得せよ！'], //外
  5: ['3ステップを習得せよ！'], //家 または外
  4: ['20m以降の走りを身に付けよ！'], // 外
  3: ['スタートの構えを習得せよ！'], // 外
  2: ['壁押し上級を習得せよ！'], // 家
  1: ['スタートを身に付けよ!'], // 外
};

export const gradeObject5 = {
  5: '自分の走りを見てみる',
  4: '姿勢を意識して走る', // 外
  3: '足をしっかり上げる！', // 外
  2: '足を前にもってくる！', // 家
  1: 'これまでの成果を走りで見せよ！', // 外
};
