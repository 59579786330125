import React, { FC, useEffect } from 'react';
import { TabInfo } from 'components/TabInfo';
import { TimeViewer } from './TimeViewer';
import { VideoPlayer } from 'pages/ResultPage/components/VideoPlayerWithoutFrame';
import { Card } from '@material-ui/core';
import { ResultComponent } from 'pages/FiftyResultPage/components/ResultComponent';
import { SuggestMenu } from './SuggestMenu';

import { RunnerType, VideoType } from 'adapters/repositories/typeDefinition';
// eslint-disable-next-line
import { Excuse } from '../BasicInfo';
import { putRunnerInfo } from 'v1apiRequest';

type Props = {
  runner: RunnerType;
  video: VideoType;
};

export const DiagnoseViewer: FC<Props> = ({ video, runner }) => {
  useEffect(() => {
    console.log('hook');
    if (!video.score || runner.onboarding === video.id) {
      return;
    }
    // 最新のビデオの結果を見た場合
    if (runner.latest_exam_video[0].id === video.id) {
      console.log('put');
      putRunnerInfo({ id: runner.id, onboarding: video.id });
    }
  }, [runner]);

  if (!video.score) {
    return (
      <>
        <Card>
          {' '}
          <VideoPlayer isLoading={false} videoInfo={video} />
        </Card>
        <div>現在ビデオを診断中です。</div>
      </>
    );
  }

  return (
    <TabInfo labels={['フォーム分析', '予想タイム', 'おすすめ練習']}>
      <>
        <div style={{ textAlign: 'center' }}>
          <ResultComponent video={video} />
        </div>
      </>

      <>
        <Card>
          {' '}
          <VideoPlayer isLoading={false} videoInfo={video} />
        </Card>
        <TimeViewer video={video} />
        <Excuse />
      </>
      <>
        <div style={{ textAlign: 'center' }}>
          <SuggestMenu video={video} runner={runner} />
        </div>
      </>
    </TabInfo>
  );
};
