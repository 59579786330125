import { RunnerType } from 'adapters/repositories/typeDefinition';
import { onboardType } from '../components/GuidanceTools/type';

// beforeLetterだけはrunnerの状態で決まる
export const decideOnboardState = (currentRunner: RunnerType) => {
  const onboardPhase =
    (localStorage.getItem(String(currentRunner.id)) as onboardType) ||
    ('beforeMsg' as onboardType);

  console.log(onboardPhase, currentRunner);
  const latestExamVideo =
    (currentRunner.latest_exam_video.length > 0 &&
      currentRunner.latest_exam_video[0]) ||
    undefined;

  // 最新の結果をまだみてない時だけ、ルーペか手紙.
  if (!!latestExamVideo && currentRunner.onboarding !== latestExamVideo.id) {
    if (!!latestExamVideo && latestExamVideo.analysis_status === 'notyet') {
      return 'Loupe';
    }

    if (!!latestExamVideo && latestExamVideo.analysis_status === 'done') {
      return 'beforeRead';
    }
  }

  return onboardPhase;
};
