import React, { FC, memo } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import FitnessCenterOutlinedIcon from '@mui/icons-material/FitnessCenterOutlined';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from './footer.module.css';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import { decideOnboardState } from 'pages/MemberHome/func/decideOnboardState';
import { Badge } from '@mui/material';

type Props = {
  runner: RunnerType | undefined;
};

export const FooterComponent: FC<Props> = memo(({ runner }) => {
  const navigate = useNavigate();
  const location = useLocation();
  React.useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  const onboardState = !!runner && decideOnboardState(runner);

  const [currentPath, setCurrentPath] = React.useState('/');

  const iconSize = 24;

  return (
    <div className={classes.footer}>
      <div style={{ display: 'flex', width: '100%' }}>
        <div
          style={{ textAlign: 'center', width: '25%' }}
          onClick={() => navigate('/')}
        >
          {currentPath === '/' ||
          currentPath.indexOf('/login') !== -1 ||
          currentPath.indexOf('/relogin') !== -1 ||
          currentPath.indexOf('/unmember-login') !== -1 ||
          currentPath.indexOf('up') !== -1 ? (
            <>
              <HomeIcon
                className={classes.selectedIcon}
                sx={{ fontSize: iconSize }}
              />
              <div className={classes.selectedCharacter}>ホーム</div>
            </>
          ) : (
            <>
              <HomeOutlinedIcon
                sx={{ fontSize: iconSize }}
                className={classes.unselectedIcon}
              />
              <div className={classes.unselectedCharacter}>ホーム</div>
            </>
          )}
        </div>

        <div
          style={{ textAlign: 'center', width: '25%' }}
          onClick={() => navigate('/logs')}
        >
          {currentPath === '/logs' || currentPath.indexOf('/result') !== -1 ? (
            <>
              <DateRangeIcon
                className={classes.selectedIcon}
                sx={{ fontSize: iconSize }}
              />
              <div className={classes.selectedCharacter}>練習のきろく</div>
            </>
          ) : (
            <>
              <DateRangeIcon
                className={classes.unselectedIcon}
                sx={{ fontSize: iconSize }}
              />
              <div className={classes.unselectedCharacter}>練習のきろく</div>
            </>
          )}
        </div>

        <div
          style={{ textAlign: 'center', width: '25%' }}
          onClick={() => navigate('/practice')}
        >
          {currentPath === '/practice' ? (
            <>
              <FitnessCenterIcon
                className={classes.selectedIcon}
                sx={{ fontSize: iconSize }}
              />
              <div className={classes.selectedCharacter}>トレーニング</div>
            </>
          ) : (
            <>
              {onboardState !== 'beforePractice' && (
                <>
                  <FitnessCenterOutlinedIcon
                    className={classes.unselectedIcon}
                    sx={{ fontSize: iconSize }}
                  />
                  <div className={classes.unselectedCharacter}>
                    トレーニング
                  </div>
                </>
              )}
              {onboardState === 'beforePractice' && (
                <>
                  <div>
                    <Badge variant="dot" color="warning">
                      <FitnessCenterOutlinedIcon
                        className={classes.unselectedIcon}
                        sx={{ fontSize: iconSize }}
                      />
                    </Badge>
                  </div>
                  <div className={classes.badgeCharacter}>トレーニング</div>
                </>
              )}
            </>
          )}
        </div>

        <div
          style={{ textAlign: 'center', width: '25%' }}
          onClick={() => navigate('/announce')}
        >
          {currentPath === '/announce' ||
          currentPath.indexOf('/announce') !== -1 ? (
            <>
              <ContactSupportIcon
                className={classes.selectedIcon}
                sx={{ fontSize: iconSize }}
              />
              <div className={classes.selectedCharacter}>サポート</div>
            </>
          ) : (
            <>
              <ContactSupportOutlinedIcon
                sx={{ fontSize: iconSize }}
                className={classes.unselectedIcon}
              />
              <div className={classes.unselectedCharacter}>サポート</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
});
