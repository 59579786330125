import React, { FC, useEffect, useState } from 'react';

import { GradeSheet } from './components/GradeSheet';
import { PageTitle } from '../../components/common/PageTitle';
import { useFirstOnboardingModal } from 'hooks/FirstOnboarding/useFirstOnboardingModal';
import { useGetDaus } from 'hooks/useGetDaus';
import loadingGif from 'static/running-101-gif.gif';
import { useFetchAccount } from './hooks/useFetchAccount';
import { GuidanceTools } from './components/GuidanceTools';
import { onboardType } from './components/GuidanceTools/type';
import { decideOnboardState } from './func/decideOnboardState';
import { AnnounceBar } from './components/AnnounceBar';
import { useNavigate } from 'react-router-dom';

export const MemberHomePage: FC = () => {
  const [onboardState, setOnboardState] = useState<onboardType>('beforeMsg');
  const { fetchedAccount, currentRunner } = useFetchAccount();
  useGetDaus((!!currentRunner && currentRunner.id) || '');
  const { forceModalOpen, OnboardModal } =
    useFirstOnboardingModal(currentRunner);

  const navigate = useNavigate();

  // 10級の場合にオンボーディングが必要ならモーダル表示
  useEffect(() => {
    if (!currentRunner || currentRunner.grade > 0) {
      return;
    }
    const isOnboardSeen =
      localStorage.getItem(String(currentRunner.id)) || 'yet';
    const shouldOnboard =
      ['yet'].includes(isOnboardSeen) && currentRunner.grade === 0;
    console.log('shouldOnboard=', shouldOnboard);
    if (shouldOnboard) {
      forceModalOpen(true);
    }
  }, [currentRunner]);

  // レンダリングと同時にonboardの状態を取る
  useEffect(() => {
    if (!currentRunner) {
      return;
    }
    const onboardPhase = decideOnboardState(currentRunner);
    console.log(onboardPhase);
    setOnboardState(onboardPhase);
  }, [currentRunner]);

  const onCloseTreasureBox = () => {
    if (onboardState === 'beforeMsg') {
      console.log(onboardState);
      if (!!currentRunner && currentRunner.grade === 0) {
        setOnboardState('beforePractice');
        navigate('/');
      }
      if (!!currentRunner && currentRunner.grade > 0) {
        setOnboardState('beforeChallenge');
      }
    }
    return;
  };

  if (!fetchedAccount || !currentRunner) {
    return (
      <>
        <PageTitle title="トップページ" />
        <img
          alt=""
          src={loadingGif}
          height="140"
          width="auto"
          style={{ marginTop: '30px' }}
        />
      </>
    );
  }

  return (
    <div style={{ textAlign: 'center' }}>
      {OnboardModal}
      <AnnounceBar onboardPhase={onboardState} runner={currentRunner} />

      <div
        style={{
          textAlign: 'center',
          padding: '5px',
          margin: '16px 0px 8px 0px',
        }}
      >
        <GradeSheet runner={currentRunner} onboardState={onboardState} />

        <GuidanceTools
          runner={currentRunner}
          onboardState={onboardState}
          onCloseTreasureBox={onCloseTreasureBox}
        />
      </div>
    </div>
  );
};
