// 10級パッケージの9級
type ckptTypeForGrade_11_20kyu =
  | 'よい姿勢'
  | '足の上がり'
  | '体をたおせているか';

export const ckptForGrade_11_20kyu: ckptTypeForGrade_11_20kyu[] = [
  'よい姿勢',
  '足の上がり',
  '体をたおせているか',
];

export const requirement_11_20kyu: ckptTypeForGrade_11_20kyu[] = [
  'よい姿勢',
  '足の上がり',
];
