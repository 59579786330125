import {
  ScoreEditType, //eslint-diable-next-line
} from 'components/MenuConst/GradeGoalConst';

import { RunnerType } from 'adapters/repositories/typeDefinition';
import { gradeTargetDict_20 } from 'components/MenuConst/GoalsForEachGrade/targetsForEachGrade';

export function crtComment15th_20(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  const setence1 = crt_line1(score, yobina);
  const sentence2 = crt_line2(runnerInfo, score, yobina);
  const sentence3 = crt_line3(score);
  return (
    setence1 +
    '<<paragraph>>' +
    sentence2 +
    '<<paragraph>>' +
    sentence3 +
    '<<paragraph>>'
  );
}

function crt_line1(score: ScoreEditType, yobina: string) {
  const head1 = yobina + '、走り方が少しずつ変わってきたかな？';
  const head2 = `今回の動画は18級の目標である「${gradeTargetDict_20['18']}」`;
  let evaluation =
    score.eval === '◯合格'
      ? 'が大変良くできています！17級昇級おめでとうございます！'
      : 'に少しだけ課題があります。';
  return head1 + head2 + evaluation;
}

function crt_line2(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  console.log(score);
  return commentLogic(score, yobina);
}

const commentLogic = (score: ScoreEditType, yobina: string) => {
  if (score['前後傾'] === '×:前傾しすぎ') {
    return `${yobina}の一番の伸びしろポイントとして「体を少し前に倒しすぎている」ことがあります。そのためには「前に進もうとしすぎないこと」が大切です！5m進むのに10歩かけてみましょう！視線についてもまっすぐ前をみるか、15m先の地面を見るか、やりやすい方の意識を試してみましょう（あごは少し引くくらいがよいです）！ そこができるとバランスがとりやすくなり足も高くキープしやすくなりますよ！`;
  }

  if (score['腰が落ちていない'] === '×:落ちている（膝のつぶれ）') {
    return `まず${yobina}のまずよかった点としては、「腰を折らずに」走ることが出来ていることです。そんな${yobina}の一番の伸びしろポイントとしては「少しひざが曲がってしまっている」ことがあります。腰を高い位置に保つような意識をしてみましょう！イメージとしては「キーホルダーになった気分で頭のてっぺんを上から紐でつられている」ような感じです（背伸びする感じです）！それができると楽に足も上がるようになりますよ！`;
  }

  if (score['リズム'] === '×:おかしい') {
    return `${yobina}の伸びしろポイントとして「まだ少しリズムがつかめていない」ことがあります。1・2・3と声に出しながらそれに合わせて足を動かしてみましょう（3のときにピタッっと止まってみて下さい）！よろしければ保護者様が隣で手を叩く・リズムを口にするなどしてそれに合わせる形でできるとさらによいです！`;
  }

  if (score['ハイニーができている'] === '×:足が上がっていない') {
    if (score['股関節から動かせている'] === '×:膝下だけ動いている') {
      return `${yobina}の伸びしろポイントとして「より足が前でおおきく動く」とよいです！そのために「足をつけ根から大きく動かす」感覚を覚えましょう！まずは「太ももをしっかりあげる」ことを意識してみましょう！前に進むとどうも難しいという場合は、まずはその場で練習してみましょう！またこの練習では前にたくさん進む必要はありません！5m進むのに10歩かけてみてください！さらに慣れてきたら「上から空き缶を潰すように地面を踏む」ことを意識しましょう！楽に足を入れ替えることが出来ますよ！`;
    }

    return `${yobina}の伸びしろポイントとして「より足が前でおおきく動く」とよいです！そのために、「足をつけ根から大きく動かす」感覚を覚えましょう！まずは「太ももをしっかりあげる」ことを意識してみましょう！前に進むとどうも難しいという場合は、まずはその場で練習してみましょう！またこの練習では前にたくさん進む必要はありません！5m進むのに10歩かけてみましょう！さらに慣れてきたら「上から空き缶を潰すように地面を踏む」ことを意識しましょう！楽に足を入れ替えることが出来ますよ！`;
  }

  if (score['ベースポジションで止まる'] === '×:止まれていない') {
    return `${yobina}の伸びしろポイントは「ベースポジションでピタッととまる」ことです！前に進むとどうも難しいという場合は、まずはその場で練習してみましょう！だるまさんが転んだハイニーを復習するのもよいですね！またこの練習では前にたくさん進む必要はありません！5m進むのに10歩かけてみましょう！`;
  }

  if (score['うで振り'] === '×:悪い') {
    return `${yobina}の伸びしろポイントはずばり「腕振り」です！腕をリズムよく振れると足が自然に動いてくれます！「腕を下にポンポン振ったら足もポンポン下に踏める」、「3でベースポジションでとまるときは腕もしっかり上がった状態でとまっている」というのを意識できるよいですね！これが出来るようになると走るのがとっても楽になりますよ！`;
  }

  if (score['前後傾'] === '×:後傾しすぎ') {
    return `${yobina}の伸びしろポイントは「少し体が後傾してしまっている」ことです！ まっすぐ前をみるか、15m先の地面を見るか、やりやすい方の意識を試してみましょう！ そこができるとあがっている足を下に踏みやすくなり、より足を空中で入れかえやすくなりますよ！`;
  }

  if (score['リーチアウト'] === '×:足が開いている') {
    return `${yobina}の伸びしろポイントは「足が上がったときに膝が大きく開いてしまっている」ことです！足が上がったときに膝を90度にたたむように意識してみましょう！そこができるとあがっている足を下に踏みやすくなり、より足を空中で入れかえやすくなりますよ！`;
  }

  return 'これはもう文句なしですね、すばらしいです！';
};

function crt_line3(score: ScoreEditType) {
  let evaluation =
    score.eval === '◯合格'
      ? `17級ではいったん今までの復習をしてもらいます！ちょうど良いタイミングですので今までの練習を一度ふりかえって見ましょう！`
      : '####いい具合になぐさめる####';
  return evaluation;
}
