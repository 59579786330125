import React from 'react';
import classes from 'components/MenuConst/menutextDecoration.module.css';

/////////////////////////////////////////////////////////
//up
/////////////////////////////////////////////////////////

export const dribbleJumpVideos = [
  {
    link: 'https://www.youtube.com/embed/F_RFxTAZaIE',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1: 「いい姿勢」を作ってみる！
        </div>
        <div className={classes.content}>
          ☆保護者さまなどにうまくできているかチェックしてもらおう！
          <br />
          ☆このメニューはおうちの中でも出来ます！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/eMTtXbAfJ9I',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:トランポリンジャンプ10回を2セット行う！
        </div>
        <div className={classes.content}>
          ☆いい姿勢だとポンポン跳ねやすいことを体感してみよう！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！
          <div className={classes.caution}>
            ※足音がなりますので、気になる方はご注意ください！
          </div>
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/JjRv0t6pUiU',
    title: 'ドリブルジャンプ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画3:「ドリブルジャンプ10回」× 3セット（目安）行う！
        </div>
        <div className={classes.content}>
          ☆ひざをかたくして、体が弾む感覚＝からだのバネを感じてみよう！
        </div>
      </div>
    ),
  },
];

/////////////////////////////////////////////////////////

export const basePositinJumpVideos = [
  {
    link: 'https://www.youtube.com/embed/cbhe66Q-s18',
    title: 'ベースポジション',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1: 「ベースポジション」を学ぶ
        </div>
        <div className={classes.content}>
          →「ベースポジションで15秒キープ」× 左右足2回ずつチャレンジ！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/AvMfxvRFdIw',
    title: '小刻み腿上げ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2: 「こきざみもも上げ20回」× 左右足2セットずつチャレンジ！
        </div>
        <div className={classes.content}>
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/NXabBLVbK9Q',
    title: 'ベースポジションジャンプ',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画3: 「ベースポジションジャンプ10回」× 左右足2セットずつ行う！
        </div>
        <div className={classes.content}>
          ☆ドリブルジャンプの時のように、地面についているあし側の肩をかるーくドリブルしてあげると弾む感覚がつかみやすいかもしれません！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！※足音がなりますので、気になる方はご注意ください！
        </div>
      </div>
    ),
  },
];

/////////////////////////////////////////////////////////
//tech
/////////////////////////////////////////////////////////

export const pongpongJogVideos = [
  {
    link: 'https://www.youtube.com/embed/-fqFjaMUJSE',
    title: 'その場足踏み',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:その場足踏み20歩を2セット行う！
        </div>
        <div className={classes.content}>
          ☆足はうまく動かせているかな？慣れたらうでもふってみよう！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！
          <div className={classes.caution}>
            ※足音がなりますので、気になる方はご注意ください！
          </div>
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/T2HRw7j0sw4',
    title: 'ポンポンジョギング',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:ポンポンジョグ20m を 4セット行う！
        </div>
        <div className={classes.content}>
          ☆ポンポン口ずさむとやりやすいよ！姿勢にちゅうい！
        </div>
      </div>
    ),
  },
];

export const ySprintVideos = [
  {
    link: 'https://www.youtube.com/embed/Ocdeu6RgRuY',
    title: 'Y字しせいの作り方',
    message: (
      <div className={classes.subtitle}>動画1:Y字しせいの作り方を確認！</div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/69otlz4YMvw',
    title: 'Y字しせいで走る(お手本)',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「Y字しせいで20m走る」 5本（目安）行う！
        </div>
        <div className={classes.title_important}>ポイント</div>
        <div className={classes.content}>
          1. 体がまっすぐ ☆手の位置を高くたもって体をたおしすぎないのがだいじ！
          <br />
          2. ポンポン走る ☆おしりの位置はたか～くできているかな？
        </div>
        <div className={classes.title_important}>
          うえ2つができた子はチャレンジ！
        </div>
        <div className={classes.content}>
          3. なれてきた子は「スピードを出す」にもチャレンジしてみよう！
          <br />
          ☆タオルを用意できる方は（動画3）を参考に、タオルを持った状態でも行ってみよう！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/5BSoqAD4_ZM',
    title: 'タオルY字姿勢',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画3:タオルを持ってY字しせいで走る！
        </div>
        <div className={classes.content}>☆とてもおすすめです！</div>
      </div>
    ),
  },
];

/////////////////////////////////////////////////////////
//run
/////////////////////////////////////////////////////////

export const yPracticeVideos = [
  {
    link: 'https://www.youtube.com/embed/GMQ9fXNMdjA',
    title: 'Y字スキップ',
    message: (
      <div className={classes.subtitle}>
        動画1:「Y字スキップ15m」× 3本（目安）を行う！
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/69otlz4YMvw',
    title: 'Y字しせいで走る(お手本)',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「Y字しせいで10m走る⇒腕をおろして15走る」× 3本（目安）行う！
        </div>
        <div className={classes.content}>
          ☆腕をおろした後もしせいをくずさずポンポン走ろう！
          <br />
          ☆Y字しせいで走るときのポイントを忘れちゃった子は下の動画で復習しよう！
        </div>
      </div>
    ),
  },
];

/////////////////////////////////////////////////////////

export const springVideos = [
  {
    link: 'https://www.youtube.com/embed/1isIaXlEFZw',
    title: 'ベースポジションジャンプ移動',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「ベースポジションジャンプ移動」をたくさんやってみる（目安3～4分）！！
        </div>
        <div className={classes.content}>
          ☆両方の足でやってみよう！かっこいい姿勢の移動できるかな？
          <br />
          ☆よろしければ保護者の方は肩を軽く押してあげて下さい！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/QYWtDk9aCuM',
    title: '全力ケンケン前進',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「全力ケンケン」にチャレンジ！
        </div>
        <div className={classes.content}>
          ☆何歩で行けたかな？よかったら毎練習自己ベストに挑戦しよう！
          <br />
          ☆両方の足でやってみよう！負荷が大きいのでやりすぎ注意！（目安は左右1～3回ずつ）！
        </div>
      </div>
    ),
  },
];

/////////////////////////////////////////////////////////
//family
/////////////////////////////////////////////////////////

export const fiftyTrialVideos = [
  {
    link: 'https://www.youtube.com/embed/X4x_Adf490I',
    title: 'かけっこ診断',
    message: (
      <div>
        <div className={classes.subtitle_center}>
          かけっこ診断をやってみよう！
        </div>
        <div className={classes.content}>
          ☆30mほどの直線が取れれば診断可能！思いっきり走る練習は足が速くなるうえでとっても大事です！
        </div>
        <div className={classes.title_important}>チャレンジ方法</div>
        <div className={classes.content}>
          1.
          動画をチェックしたうえで、撮影方法をしっかり守って動画を撮影！自己ベスト目指して思いっきり走ろう！
          <br />
          2.
          画面右下の+ボタンをタップ⇒かけっこ診断をタップ⇒案内に従って動画を送信！
        </div>
        <div className={classes.title_important}>
          撮影方法☆必ずお守りください！
        </div>
        <div className={classes.caution}>
          1. 通常速度の動画を撮影しよう！（スロー動画はNG！）
          <br />
          2.
          カメラマンはお子様のスタートから20m地点に立って、カメラを固定して真横から撮影！カメラにはおおよそ5.6歩程度うつるようにとるとGood！
          <br />
          3.
          人の少ない明るい場所で撮ろう！暗かったり人混みだとAIがお子様を認識できない恐れがあります！
        </div>
      </div>
    ),
  },
];

export const armSwingVideos = [
  {
    link: 'https://www.youtube.com/embed/xwRSfjwvLMs',
    title: 'その場うでふり',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画1:「その場腕振り」を20回 × 3セット行う！
        </div>
        <div className={classes.content}>
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/mXkqgxMT_l0',
    title: 'リズム腕振り',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2:「リズム腕振り」を20回 × 3セット行う！
        </div>
        <div className={classes.content}>
          ☆保護者さまはよろしければ手をたたいてリズムをとってあげてください！はじめはゆっくり、慣れてきたら1秒に4回のリズムがおすすめです！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/WqPD2RWdb70',
    title: 'ペットボトル腕振り',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画3:「ペットボトル腕振り」を20回 × 3セット行う！
        </div>
        <div className={classes.content}>
          ☆動画では500mlのペットボトルに1/3～1/2程度水をいれています！
          <br />
          ☆このメニューはおうちの中でも出来るので、よかったら毎日練習してみてね！
        </div>
      </div>
    ),
  },
];

export const howToStartPoseVideos = [
  {
    link: 'https://www.youtube.com/embed/OUNYO_6z5mQ',
    title: 'スタートの構え',
    message: (
      <div>
        <div className={classes.subtitle}>動画1: スタートの構えを学ぼう！</div>
        <div className={classes.content}>
          ☆この動画はいったん見るだけで大丈夫です！
        </div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/Yc3vnf-dbck',
    title: 'スタートの前足の決め方',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画2: スタートの前足を決めよう！
        </div>
        <div className={classes.content}>☆おうちのなかでも出来ます！</div>
      </div>
    ),
  },
  {
    link: 'https://www.youtube.com/embed/p94g4g_NmHc',
    title: 'スタートの構えの作り方',
    message: (
      <div>
        <div className={classes.subtitle}>
          動画3: スタートのかまえをつくれるようになろう！
        </div>
        <div className={classes.content}>
          ☆動画のお手本の形をマネして、保護者さんなどにうまくできているかチェックしてもらおう！
          <br />
          ☆おうちのなかでも出来ます！
        </div>
      </div>
    ),
  },
];
