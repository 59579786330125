// 10級パッケージの1級
type ckptTypeForGrade_9_20kyu = 'しっかり体を倒す' | '足を前に前に' | '腕ふり';

export const ckptForGrade_9_20kyu: ckptTypeForGrade_9_20kyu[] = [
  'しっかり体を倒す',
  '足を前に前に',
  '腕ふり',
];

export const requirement_9_20kyu: ckptTypeForGrade_9_20kyu[] = [
  'しっかり体を倒す',
  '足を前に前に',
];

type ckptDetails =
  | '六歩以上体を倒せている'
  | '足の流れ'
  | 'リーチアウト'
  | '足の上がり'
  | 'うでを前に前に振る';

type allCheckpoints_9_20kyu_type = {
  [key in ckptTypeForGrade_9_20kyu]: ckptDetails[];
};

export const allCheckPoints_9_20kyu: allCheckpoints_9_20kyu_type = {
  しっかり体を倒す: ['六歩以上体を倒せている'],
  足を前に前に: ['足の流れ', '足の上がり', 'リーチアウト'],
  腕ふり: ['うでを前に前に振る'],
};

export const borderLine_9_20kyu: allCheckpoints_9_20kyu_type = {
  しっかり体を倒す: ['六歩以上体を倒せている'],
  足を前に前に: ['足の流れ', '足の上がり'],
  腕ふり: ['うでを前に前に振る'],
};
