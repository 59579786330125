// 10級パッケージの1級
type ckptTypeForGrade_19_20kyu = 'しっかり体を倒す' | '足を前に前に' | '腕ふり';

export const ckptForGrade_19_20kyu: ckptTypeForGrade_19_20kyu[] = [
  'しっかり体を倒す',
  '足を前に前に',
  '腕ふり',
];

export const requirement_19_20kyu: ckptTypeForGrade_19_20kyu[] = [
  'しっかり体を倒す',
  '足を前に前に',
];
