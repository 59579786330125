import React, { FC, memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
// import classes from './GuidanceTools/guidanceTools.module.css';
import { TreasureBox } from './GuidanceTools/TreasureBox';
import { Dumbbell } from './GuidanceTools/Dumbbell';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import { Crown } from './GuidanceTools/Crown';
import { Letter } from './GuidanceTools/Letter';
import { SearchButton } from './GuidanceTools/SearchButton';
import { onboardType } from './GuidanceTools/type';
import { putRunnerInfo } from 'v1apiRequest';

type Props = {
  runner: RunnerType;
  onboardState: onboardType;
  onCloseTreasureBox: () => void;
};

export const GuidanceTools: FC<Props> = memo(
  ({ runner, onboardState, onCloseTreasureBox }) => {
    // 毎回オンボーディングの段階を確認する
    const navigate = useNavigate();
    const latestExamVideo =
      (runner.latest_exam_video.length > 0 && runner.latest_exam_video[0]) ||
      null;

    const onClickLetter = useCallback(() => {
      if (!!latestExamVideo && latestExamVideo.analysis_status === 'done') {
        const nextState =
          (runner.stay_times > 0 && 'beforeChallenge') || 'beforeMsg';
        localStorage.setItem(String(runner.id), nextState);
        putRunnerInfo({ id: runner.id, onboarding: nextState });
        navigate(`/result/${latestExamVideo.id}`);
      }
      return;
    }, [runner]);

    return (
      <>
        <TreasureBox
          runner={runner}
          onClickFunc={onCloseTreasureBox}
          onboardState={onboardState}
        />
        <Dumbbell onboardState={onboardState} runner={runner} />
        <Crown onboardState={onboardState} />
        <SearchButton onboardState={onboardState} video={latestExamVideo} />
        <Letter onClickFunc={onClickLetter} onboardState={onboardState} />
      </>
    );
  },
);
