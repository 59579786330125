import React, { FC, memo } from 'react';

import '../calendar.css';
import { animalDict } from '../../../components/Sticker/StickerModal';
import sample_sticker from 'static/sample_sticker.png';
import {
  createCalendar,
  stringifyDate,
  sortVideoByDate,
  formatUploadDate,
} from '../func/simpleFuncs';
import { CalendarModal } from 'components/modals/CalendarModal';
import { VideoType } from 'adapters/repositories/typeDefinition';

type Props = {
  year: number;
  month: number;
  videoMonthly: VideoType[];
};

type videoDataType = {
  [key: string]: {
    grade: number;
    video: VideoType[];
  };
};

export const StickerCalendar: FC<Props> = memo(
  ({ year, month, videoMonthly }) => {
    const dateList = ['日', '月', '火', '水', '木', '金', '土'];
    console.log(videoMonthly);

    const calendar = createCalendar(year, month);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState<string>('');

    const handleStickerClick = (dateStr: string) => () => {
      setSelectedDate(dateStr);
      setIsModalOpen(true);
    };

    const today = new Date();
    const todayStr = stringifyDate(
      today.getFullYear(),
      today.getMonth() + 1,
      today.getDate(),
    );

    const videoData = formatVideoMonthly(videoMonthly) as videoDataType;

    // これはモーダルを浮かび上がらせるために必要
    const videos =
      !!selectedDate && !!videoData[selectedDate]
        ? videoData[selectedDate]['video']
        : [];

    return (
      <div style={{ textAlign: 'center', padding: '10px' }}>
        <CalendarModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          videos={videos}
          date={selectedDate}
        />
        <div style={{ textAlign: 'center' }}>
          <table className="calendar">
            <tbody>
              <tr>
                {dateList.map((yobi) => {
                  return (
                    <th className="date" key={yobi}>
                      {yobi}
                    </th>
                  );
                })}
              </tr>
              {calendar.map((week) => {
                if (week.every((w) => !w)) return <></>; //全てがnullだったら出さない
                return (
                  <tr key={week.join('')} className="dateCell">
                    {week.map((day) =>
                      renderSticker(
                        year,
                        month,
                        day,
                        todayStr,
                        videoData,
                        handleStickerClick,
                      ),
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    );
  },
);

function renderSticker(
  year: number,
  month: number,
  day: number | null,
  todayStr: string,
  videoData: videoDataType,
  handleStickerClick: any,
) {
  if (!day) {
    return <td className="empty-day"></td>;
  }
  const dateStr = stringifyDate(year, month, day);

  // 練習日じゃない
  if (Object.keys(videoData).indexOf(dateStr) === -1) {
    // もし今日だったらサンプル的にスタンプがある
    if (dateStr === todayStr) {
      return (
        <td key={dateStr} style={{ backgroundColor: '#ffd1a3' }}>
          <div>{day}</div>
          <figure className="outline-gray">
            {' '}
            <div
              style={{
                width: '25px',
                height: '25px',
              }}
            ></div>
          </figure>
        </td>
      );
    }

    //それ以外の何もない日
    return (
      <td key={dateStr}>
        <div>{day}</div>
        <figure className="outline-gray">
          {' '}
          <div
            style={{
              width: '25px',
              height: '25px',
            }}
          ></div>
        </figure>
      </td>
    );
  }

  // 練習日なのでこの日のgradeを計算
  const grade = videoData[dateStr]['grade'];

  return (
    <td key={dateStr}>
      <div>{day}</div>

      <figure className="outline" onClick={handleStickerClick(dateStr)}>
        {' '}
        <img
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          src={animalDict[String(10 - grade)]}
          alt="動物"
          width="25px"
          height="25px"
        />{' '}
      </figure>
    </td>
  );
}

function formatVideoMonthly(videoMonthly: VideoType[]) {
  sortVideoByDate(videoMonthly);
  let retObj = {};
  for (let vObj of videoMonthly) {
    const uploadDate = formatUploadDate(vObj);
    if (!Object.keys(retObj).includes(uploadDate)) {
      // videoを事前にソートするので、gradeには一番新しいものが入る
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      retObj[uploadDate] = {
        video: [vObj],
        grade: vObj.grade_before,
      };
      continue;
    }
    // すでにあった場合
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    let currentInfoOfTheDay = retObj[uploadDate];
    currentInfoOfTheDay['video'].push(vObj);
  }
  return retObj;
}
