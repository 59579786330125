import {
  gradeGoalDict,
  ScoreEditType,
} from 'components/MenuConst/GradeGoalConst';

import { RunnerType } from 'adapters/repositories/typeDefinition';

export function crtComment5th(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  const setence1 = crt_line1(score, yobina);
  const sentence2 = crt_line2(runnerInfo, score, yobina);
  const sentence3 = crt_line3(score, yobina);
  return (
    setence1 +
    '<<paragraph>>' +
    sentence2 +
    '<<paragraph>>' +
    sentence3 +
    '<<paragraph>>'
  );
}

function crt_line1(score: ScoreEditType, yobina: string) {
  const head1 =
    yobina +
    '、5級の昇級テストは少し難しかったと思うけど、よく頑張りましたね！';
  const head2 = `今回の動画は5級の目標である「${gradeGoalDict['5']}」`;
  let evaluation =
    score.eval === '◯合格'
      ? 'が大変良くできています！4級昇級おめでとうございます！'
      : 'に少しだけ課題があります。';
  return head1 + head2 + evaluation;
}

function crt_line2(
  runnerInfo: RunnerType,
  score: ScoreEditType,
  yobina: string,
) {
  console.log(score);
  return commentLogic(score, yobina);
}

const commentLogic = (score: ScoreEditType, yobina: string) => {
  if (score['前後傾'] === '×:前傾しすぎ') {
    return `${yobina}の一番の伸びしろポイントとして「体を少し前に倒しすぎている」ことがあります。 そのためには「前に進もうとしすぎないこと」が大切です！まず始める前にいい姿勢を作った上で、5m進むのに10歩かけてみましょう！視線についてもまっすぐ前をみるか、15m先の地面を見るか、やりやすい方の意識を試してみましょう！ そこができるとバランスがとりやすくなり足も高くキープしやすくなりますよ！`;
  }

  if (score['腰が落ちていない'] === '×:落ちている（膝のつぶれ）') {
    return `まず${yobina}のよかった点としては、「腰が折れずに」3ステップを出来ていますね！そんな${yobina}の伸びしろポイントとして「少し腰の位置が落ちてしまっている」ことがあります。腰を高い位置に保つような意識をしてみましょう！イメージとしては「キーホルダーになった気分で頭のてっぺんを上から紐でつられている」ような感じです！とても楽に3ステップが出来るようになりますよ！はじめは難しいので、3ステップを横に移動しながらやってみたり壁に手をついてやってみたり、ベースポジションケンケンで前や横に移動する練習もよいかもしれません。その際もまずは「腰が落ちないようにおへそやおしりを高い位置にキープ/視線も下をみると腰が落ちやすいのでなるべくまっすぐ前をみる」を意識してみてください！`;
  }

  if (score['足の上がり'] === '×:足が上がっていない') {
    if (score['後ろに蹴っていない'] === '×:蹴ってしまっている') {
      return `まず${yobina}のよかった点としては、「腰が折れずに」3ステップを出来ていますね！ただ${yobina}の伸びしろポイントとして「前に進もうとしすぎてしまっています」。その結果あしでうしろに蹴って進む形になり（本当は体を倒したら自然と進むとGood！）バランスが崩れ、足をベースポジションにキープできなくなってしまっています！まずはその場での3ステップ⇒次は3ステップで横に移動⇒最後は3ステップで前に少しずつ進むといった段階を踏んでみて下さい！そのときの注意ポイントは①姿勢（いい姿勢か/腰が落ちていないか）②足がしっかり上がっているか③空中で足を入れ替えられているか、の順に意識してみて下さい！`;
    }

    return `まず${yobina}のよかった点としては、いい姿勢で3ステップを出来ていますね！とにかく基本は姿勢なのでそこが出来ているのは素晴らしいです！そんな${yobina}の伸びしろポイントは「太ももを地面と平行まで上げてキープする」ことです！はじめは前に進もう/足をいれかえようと思うと難しいので、①ベースポジションケンケンで前や横に移動する⇒②3ステップを横に移動しながらやってみたり壁に手をついてやってみたりする中で感覚をつかんでみて下さい！それが出来るようになったら3ステップで前に進みながら「空中であしを入れ替えられるか」にチャレンジしてみて下さい！今までも楽に足を空中で入れかえることが出来ますよ！`;
  }

  if (score['上げた足をキープできている'] === '×:上下している') {
    return `まず${yobina}のよかった点としては、いい姿勢で3ステップを出来ていますね！とにかく基本は姿勢なのでそこが出来ているのは素晴らしいです！そんな${yobina}の伸びしろポイントは「上がっている足をベースポジションの形でなるべく動かないようにする」ことです！はじめは前に進もう/足をいれかえようと思うと難しいので、①ベースポジションケンケンで前や横に移動する⇒②3ステップを横に移動しながらやってみたり壁に手をついてやってみたりする中で感覚をつかんでみて下さい！それが出来るようになったら3ステップで前に進みながら「空中であしを入れ替えられるか」にチャレンジしてみて下さい！今までも楽に足を空中で入れかえることが出来ますよ！`;
  }

  if (score['足をからだの前でいれかえる'] === '×:出来ていない') {
    if (score['うで振り'] === '×:出来ていない') {
      return `まず${yobina}のよかった点としては、ベースポジションで移動する部分はばっちりですね！そんな${yobina}の伸びしろポイントは「空中であしを入れかえる」ことです！少し難しいのですが、この練習をするときは、足をついてから逆足を上げるのではなく、「足が地面に着くと同時に逆足が上がる」というのが大事になります。はじめは前に進もうと思うと難しいので、3ステップを横に移動しながらやってみたり壁に手をついてやってみたりする中で感覚をつかんでみて下さい！足がスパンスパン体の前で入れかえられるようになると、一気に走りが大変身しますよ！また「腕」をしっかり振れると足の入れ替えがしやすいかもしれません！「足をあげるときは腕もしっかり上がっている」、「足を入れ替えるときは腕もしっかり振る」を意識できるよいですね！`;
    }

    return `まず${yobina}のよかった点としては、ベースポジションで移動する部分はばっちりですね！そんな${yobina}の伸びしろポイントは「空中であしを入れかえる」ことです！少し難しいのですが、この練習をするときは、足をついてから逆足を上げるのではなく、「足が地面に着くと同時に逆足が上がる」というのが大事になります。はじめは前に進もうと思うと難しいので、3ステップを横に移動しながらやってみたり壁に手をついてやってみたりする中で感覚をつかんでみて下さい！足がスパンスパン体の前で入れかえられるようになると、一気に走りが大変身しますよ！`;
  }

  if (score['前後傾'] === '×:後傾しすぎ') {
    return `まず${yobina}のよかった点としては、ばっちり「空中であしを入れかえる」ことができていますね！よく練習してくれたのが伝わります！そんな${yobina}に上級者向けの伸びしろポイントをお伝えすると、ずばり「少し体が後傾してしまっている」ことです！ まっすぐ前をみるか、20m先の地面を見るか、やりやすい方の意識を試してみましょう！ そこができるとあがっている足を下に踏みやすくなり、より足を空中で入れかえやすくなりますよ！`;
  }

  if (score['リーチアウト'] === '×:足が開いている') {
    return `まず${yobina}のよかった点としては、ばっちり「空中であしを入れかえる」ことができていますね！姿勢もばっちりで、よく練習してくれたのが伝わります！そんな${yobina}に上級者向けの伸びしろポイントをお伝えすると、ずばり「足が上がったときに膝が大きく開いてしまっている」ことです！足が上がったときに膝を90度にたたむように意識してみましょう！そこができるとあがっている足を下に踏みやすくなり、より足を空中で入れかえやすくなりますよ！`;
  }

  return 'これはもう文句なしですね、すばらしいです！';
};

function crt_line3(score: ScoreEditType, yobina: string) {
  let evaluation =
    score.eval === '◯合格'
      ? `それでは4級ではまた30mダッシュにチャレンジしてもらいます！さらに進化した${yobina}の走りを見るのを楽しみにしているので、是非がんばってみてください！`
      : '####いい具合になぐさめる####';
  return evaluation;
}
