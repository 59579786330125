import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import takei from 'static/coach-murai.png';
import { evalNumber } from 'components/MenuConst/CommentForEachPoint';
import { calcFormScore } from 'components/MenuConst/calcFormScore';

import classes from './timeviewer.module.css';
//eslint-disable-next-line

type Props = {
  posture: evalNumber;
  arm: evalNumber;
  leg: evalNumber;
  estTime: number;
};

export const FormScore: FC<Props> = ({ posture, arm, leg, estTime }) => {
  const shortComment = crtShortComment(posture, arm, leg);
  const formScore = calcFormScore(posture, arm, leg, estTime);
  console.log(estTime);
  return (
    <div
      style={{
        marginTop: '16px',
      }}
    >
      <div style={{ fontSize: '8pt' }}>あなたのフォームは</div>
      <div className={classes.formviewer}>
        {String(formScore)}
        <span style={{ fontSize: '12pt', color: '#424242' }}>
          &nbsp;点 &nbsp;
        </span>
      </div>
      <div style={{ margin: '12px 20px 12px 20px' }}>
        <Grid container alignItems="flex-end">
          <Grid item xs={1}>
            <img
              className={classes.muraiIcon}
              alt=""
              src={takei}
              width="90%"
              style={{ borderRadius: '50%' }}
            />
          </Grid>
          <Grid xs={11}>
            <div style={{ marginLeft: '5px' }}>
              <div className={classes.coachComment}>{shortComment}</div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const crtShortComment = (
  posture: evalNumber,
  arm: evalNumber,
  leg: evalNumber,
) => {
  ////////////
  ////posture前傾しすぎ（1,7）
  if (posture === '1') {
    if (leg === '1' || leg === '2' || leg === '3' || leg === '4') {
      return '一番の伸びしろは体が前に倒れすぎていること！あごを引いて15m先の地面をみながら走ってみよう！足も自然とあがるようになりますよ！';
    }
    if (leg === '6') {
      return '一番の伸びしろは体が前につっこみすぎていること！あごを引いて15m先の地面をみながら走ってみよう！後ろに砂のとびづらい走りになり、足が楽に戻ってくるようになりますよ！';
    }
    return '一番の伸びしろは体が前に倒れすぎていること！あごを引いて15m先の地面をみながらポンポンはずむように走ってみよう！保護者様が4回/1秒くらいのリズムで手を叩き、そのリズムと足音を合わせて走るとポンポン走りやすいかも！';
  }

  ////posture前傾しすぎ（1,7）
  if (posture === '7') {
    if (leg === '1' || leg === '2' || leg === '3' || leg === '4') {
      return '一番の伸びしろは体が前に倒れすぎていること！スピードに乗った後はあごを引いて15m先の地面をみながら走ってみよう！足も自然と下にふめるようになりますよ！';
    }
    return '一番の伸びしろは体が前に倒れすぎていること！スピードに乗った後はあごを引いて15m先の地面をみながらポンポンはずむように走ってみよう！保護者様が4回/1秒くらいのリズムで手を叩き、そのリズムと足音を合わせて走るとポンポン走りやすいかも！';
  }

  ////////////
  ////leg全然上がってない（1,2）

  //パターン１：動きが小さい（後ろに蹴ってない）（1）
  if (leg === '1') {
    //posture前傾
    if (posture === '2') {
      //腕ふり小さい
      if (arm === '1' || arm === '2' || arm === '3') {
        return '伸びしろポイントは「足のあがりが小さい」こと！まずは手足を先生のお手本くらいダイナミックに動かしてみよう！少し体を起こすとよりやりやすいよ！';
      }
      //その他
      return '伸びしろポイントは「足のあがりが小さい」こと！まずは足を先生のお手本くらいダイナミックに動かしてみよう！少し体を起こすとよりやりやすいよ！';
    }

    //腕ふり小さい
    if (arm === '1' || arm === '2' || arm === '3') {
      return '伸びしろポイントは「足のあがりが小さい」こと！まずは手足を先生のお手本くらいダイナミックに動かしてみよう！';
    }
    //その他
    return '伸びしろポイントは「足のあがりが小さい」こと！まずは足を先生のお手本くらいダイナミックに動かしてみよう！';
  }

  //パターン2：後ろに蹴っている（2）
  if (leg === '2') {
    //posture前傾
    if (posture === '2') {
      //腕ふり小さい
      if (arm === '1' || arm === '2' || arm === '3') {
        return '伸びしろポイントは「足のあがりが小さい」こと！まずは手足を先生のお手本くらい体の前でダイナミックに動かしてみよう！少し体を起こすとよりやりやすいよ！';
      }
      //その他
      return '伸びしろポイントは「足のあがりが小さい」こと！まずは足を先生のお手本くらい体の前でダイナミックに動かしてみよう！少し体を起こすとよりやりやすいよ！';
    }

    //腕ふり小さい
    if (arm === '1' || arm === '2' || arm === '3') {
      return '伸びしろポイントは「足のあがりが小さい」こと！後ろに砂が飛ばないようイメージして、手足を先生のお手本くらい体の前でダイナミックに動かしてみよう！';
    }
    //その他
    return '伸びしろポイントは「足のあがりが小さい」こと！後ろに砂が飛ばないようイメージして、足を先生のお手本くらい体の前でダイナミックに動かしてみよう！';
  }

  ////////////
  ////posture後傾（3,4）

  //後傾かつ足あがってる（3）
  if (posture === '3') {
    return '伸びしろポイントは足を上げようとしすぎてか、「体がのけぞっている」こと！あごを引いて15m先の地面を見ながら「空きカンを上からポンポンふんで走るイメージで」走ってみよう！新幹線のように前にスーっと走れるようになったらバッチリ！';
  }

  //後傾
  if (posture === '4') {
    return '伸びしろポイントは「体がのけぞっている」こと！あごを引いて15m先の地面を見ながら走ってみよう！新幹線のように前にスーっと走れるようになったらバッチリ！';
  }

  ////////////
  ////postureやや前傾（2）

  if (posture === '2') {
    //足ちょっと上がってない&流れ気味
    if (leg === '3') {
      //腕ふり小さい
      if (arm === '1' || arm === '2' || arm === '3') {
        return '伸びしろポイントは「足のあがりが少し小さい」こと！後ろに砂が飛ばないようイメージして、足を先生のお手本くらい足が上がるようにしてみよう！うでを太鼓をたたくように下にポンポン振ると足が前に戻ってきやすいよ！';
      }
      return '伸びしろポイントは「足のあがりが少し小さい」こと！後ろに砂が飛ばないようイメージして、足を先生のお手本くらい足が上がるようにしてみよう！';
    }
    //足ちょっと上がってない
    if (leg === '4') {
      //腕ふり小さい
      if (arm === '1' || arm === '2' || arm === '3') {
        return '伸びしろポイントは「足のあがりが少し小さい」こと！「足があがった姿勢を空中で一瞬キープする」ことを意識してみよう！なれてきたら「上から空き缶をつぶすように地面をふんで」走ってみよう！足の動きに合わせて腕も下にポンポン振れるとさらにバッチリ！';
      }
      return '伸びしろポイントは「足のあがりが少し小さい」こと！「足があがった姿勢を空中で一瞬キープする」ことを意識してみよう！なれてきたら「上から空き缶をつぶすように地面をふんで」走ってみよう！';
    }
    // 上がり具合はよいが流れ
    if (leg === '6') {
      return '少しだけ体がまだ前のめりになって足が後ろで回っています。あごを少し引いて15m先の地面を見ながら走ってみましょう!足も前に返ってきやすくなりますよ!';
    }

    //leg === '5'（あがりすぎ）
    if (leg === '5') {
      //腕ふりおおきい
      if (arm === '4' || arm === '5' || arm === '6') {
        return '手足を大きく動かそうと頑張りすぎかも？もう十分足は上がっているので、「空きカンを上からポンポンふんで走るイメージで」走ってみよう!うでも下にポンポンとコンパクトに振ろう！';
      }
      return '足をあげようと頑張りすぎかも？もう十分足は上がっているので、「空きカンを上からポンポンふんで走るイメージで」走ってみよう!';
    }
    //leg === '7'（といいつつ流れ気味）の時
    if (arm === '1' || arm === '2' || arm === '3') {
      return '腕をもうちょっとふれるといいですね。ひじを90度にして、あごの高さから下にポンポン振るようにして走ってみましょう！腕ふりで走りのリズムがとれると、足もより体の前でいれかえやすくなりますよ！';
    }
    if (arm === '4' || arm === '5' || arm === '6') {
      return '腕ふりがちょっと大きすぎて力んでいるかも。ひじを90度にして、あごの高さから下にポンポンコンパクトに振るようにして走ってみましょう！足もピュンっと戻ってきて、足の回転も上がりやすくなりますよ！';
    }
    return '走るのがとっても上手ですね！上級者向けのアドバイスとして、後ろに砂が飛ばないようイメージしながら、体の前で足をすばやく入れかえて走ってみましょう！その時もうすこーしだけ体を起こすと足が楽に戻ってきやすいかも？';
  }

  ////////////
  ////posture前後傾はいいが足がめちゃ流れ気味（⇒腰が引けている）（5）

  if (posture === '5') {
    return '伸びしろは腰が引けてしまっていること！まずその場で背伸びをしておしりの高い姿勢を作ったら、こしを後ろからおされているイメージで軽やかに走ってみよう！';
  }

  ////////////
  ////postureよい（6）
  if (posture === '6') {
    //足ちょっと上がってない&流れ気味
    if (leg === '3') {
      //腕ふり小さい
      if (arm === '1' || arm === '2' || arm === '3') {
        return '伸びしろポイントは「足のあがりが少し小さい」こと！後ろに砂が飛ばないようイメージして、足を先生のお手本くらい足が上がるようにしてみよう！うでを太鼓をたたくように下にポンポン振ると足が前に戻ってきやすいよ！';
      }
      return '伸びしろポイントは「足のあがりが少し小さい」こと！後ろに砂が飛ばないようイメージして、足を先生のお手本くらい足が上がるようにしてみよう！';
    }
    //足ちょっと上がってない
    if (leg === '4') {
      //腕ふり小さい
      if (arm === '1' || arm === '2' || arm === '3') {
        return '伸びしろポイントは「足のあがりが少し小さい」こと！「足があがった姿勢を空中で一瞬キープする」ことを意識してみよう！なれてきたら「上から空き缶をつぶすように地面をふんで」走ってみよう！足の動きに合わせて腕も下にポンポン振れるとさらにバッチリ！';
      }
      return '伸びしろポイントは「足のあがりが少し小さい」こと！「足があがった姿勢を空中で一瞬キープする」ことを意識してみよう！なれてきたら「上から空き缶をつぶすように地面をふんで」走ってみよう！';
    }
    // 上がり具合はよいが流れ
    if (leg === '6') {
      return '少しだけ体がまだ前のめりになって足が後ろで回っています。あごを少し引いて15m先の地面を見ながら走ってみましょう!足も前に返ってきやすくなりますよ!';
    }

    //leg === '5'（あがりすぎ）
    if (leg === '5') {
      //腕ふりおおきい
      if (arm === '4' || arm === '5' || arm === '6') {
        return '手足を大きく動かそうと頑張りすぎかも？もう十分足は上がっているので、「空きカンを上からポンポンふんで走るイメージで」走ってみよう!うでも下にポンポンとコンパクトに振ろう！';
      }
      return '足をあげようと頑張りすぎかも？もう十分足は上がっているので、「空きカンを上からポンポンふんで走るイメージで」走ってみよう!';
    }
    ////以下 leg === '7'
    //腕振り小さい
    if (arm === '1' || arm === '2' || arm === '3') {
      return '走るのがとっても上手ですね！伸びしろポイントは「腕ふりが少しちいさすぎる」こと。ひじを90度にして、あごの高さから下にポンポンをリズミカルに振るようにして走ってみましょう！腕ふりで走りのリズムがとれると、足も体の前でいれかえやすくなりますよ！';
    }
    //腕振り大きい
    if (arm === '4' || arm === '5' || arm === '6') {
      return '走るのがとっても上手ですね！伸びしろポイントは「腕ふりがちょっと大きすぎて力んでいる」こと。肩の力を抜いて、下にポンポンをリズミカルに振るようにして走ってみましょう！腕ふりで走りのリズムがとれるととても楽に走れますよ！';
    }
    //腕振り前ひじ伸びている
    if (arm === '7') {
      return '走るのがとっても上手ですね！伸びしろポイントは「ひじが伸びたまま走っている」こと。ひじを90度にして、あごの高さから下にポンポン振るようにして走ってみましょう！これが出来るとより足を体の前でいれかえやすくなりますよ！';
    }
    //腕振りひじをかかえこんでいる
    if (arm === '9') {
      return '走るのがとっても上手ですね！伸びしろポイントは「うでふりでひじをかかえこんでいる」こと！ひじをリラックスして、下にポンポンふってみましょう！これが出来るとより足を体の前でいれかえやすくなりますよ！';
    }

    //all A
    return '走るのがとっても上手ですね！あとは腕を下にポンポン振って走りのリズムがとりつつ、よりスパンッと体の前で足を入れかえられるようになるとさらにはやく走れますよ！';
  }

  return 'これには来ないはず・・・';
};
