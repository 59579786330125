import React, { FC, memo } from 'react';
import { onboardType } from './GuidanceTools/type';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import classes from './announceBar.module.css';
import { crtIdatenDefault } from './crtOnboardMsg';
import joy_boy from 'static/materials/joy_boy.png';

type Props = {
  onboardPhase: onboardType;
  runner: RunnerType;
};
export const AnnounceBar: FC<Props> = memo(({ onboardPhase }) => {
  const message = crtIdatenDefault(onboardPhase);

  return (
    <div className={classes.announceBarWrapper}>
      <div className={classes.announceBar}>
        <div style={{ width: '20%' }}>
          <img src={joy_boy} alt="" width="90%"></img>
        </div>
        <div style={{ width: '80%' }}>
          <div className={classes.blackBorderSmall}>{message}</div>
        </div>
      </div>
    </div>
  );
});
