import React from 'react';
import { useNavigate } from 'react-router-dom';

import { VideoContext } from 'contexts/VideoContext';

import { VideoCard } from 'pages/LogPage/components/VideoCard';

import 'index.css';
import 'App.css';
import { CardForFirstLogin } from 'pages/LogPage/components/CardForFitstLogin';

export const VideoListPage = () => {
  const navigate = useNavigate();
  const { videoList } = React.useContext(VideoContext);

  const changeVideoId = (vId: string) => () => {
    navigate(`/result/${vId}`);
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <div
        style={{
          margin: '3px',
          overflowY: 'scroll',
          height: `${window.parent.screen.height * 0.75}px`,
          border: '3px solid #EEEEEE',
        }}
      >
        {!!videoList && videoList.length > 0 ? (
          <>
            {videoList.map((vObj) => {
              return (
                <VideoCard
                  key={vObj.id}
                  video={vObj}
                  handleClick={changeVideoId}
                />
              );
            })}
            <CardForFirstLogin />
          </>
        ) : (
          <div>
            <p>動画がまだ投稿されていません</p>
            <CardForFirstLogin />
          </div>
        )}
      </div>
      {/* <Button onClick={handleTest}>テスト</Button> */}
    </div>
  );
};

export function formatDate(string: string) {
  const TokyoDate = new Date(string);
  let nengappi =
    TokyoDate.getFullYear() +
    '年' +
    (TokyoDate.getMonth() + 1) +
    '月' +
    TokyoDate.getDate() +
    '日' +
    TokyoDate.getHours() +
    '時' +
    TokyoDate.getMinutes() +
    '分';

  return nengappi;
}
