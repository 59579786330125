import React, { FC, useState } from 'react';
import { PageTitle } from '../../components/common/PageTitle';
import { MenuVariationSelector } from './components/MenuVariationSelector';
import { MenuListViewer } from './components/MenuListViewer';
import { decideMenu } from '../../components/MenuConst/DecideMenu';
import { menuType, menuVariationType } from './type';
import { TabInfo } from 'components/TabInfo';
import { AllMenu } from './components/AllMenu';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import { DiagnoseExplanation } from './components/HowToDiagnose';

type Props = {
  runner: RunnerType | undefined;
};

export const PracticePage: FC<Props> = ({ runner }) => {
  const [menuVariation, setMenuVariation] = useState<menuVariationType>('up');

  const menuList: menuType[] = decideMenu(runner);

  if (!runner || runner.grade < 1) {
    // 5級の時はかけっこ診断LPのようなものを出す
    return (
      <>
        <PageTitle title={'かけっこ診断のやり方'} />
        <DiagnoseExplanation runner={runner} />
      </>
    );
  }

  return (
    <div>
      <PageTitle title={'練習のときに見るページ'} />
      <TabInfo labels={['この級の練習', 'すべて', '撮影方法']}>
        <>
          <div>
            <MenuVariationSelector
              menuVariation={menuVariation}
              setMenuVariation={setMenuVariation}
            />
          </div>
          <div>
            <MenuListViewer
              menuVariation={menuVariation}
              menuPlaylists={menuList}
            />
          </div>
          <div style={{ marginTop: '100px' }}></div>
        </>
        <>
          <div>
            {!!runner && <AllMenu runner={runner} />}
            <div style={{ marginTop: '100px' }}></div>
          </div>
        </>
        <>
          <DiagnoseExplanation runner={runner} />
        </>
      </TabInfo>
    </div>
  );
};
