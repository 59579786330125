import React, { FC } from 'react';

import 'components/components.css';
import sample_thumbnail from 'static/sample_thumbnail.png';
import classes from './videoPlayer.module.css';

export const FirstLoginView: FC = () => {
  return (
    <div>
      <span style={{ fontSize: '12pt', fontWeight: 'bold' }}>
        かけっこ診断ではこのような成績表が見られます！
      </span>

      <p style={{ fontSize: '10pt' }}>成績表（例）</p>
      <div className={classes.videoWrapper}>
        <video
          style={{ margin: '3px' }}
          className={classes.videoBasic}
          src={
            'https://idaten-compressed-video.s3.amazonaws.com/video/7ec97e8fd971bce2fe82d73291193cc7.mp4'
          }
          controls
          muted
          loop
          poster={sample_thumbnail}
        ></video>
      </div>
    </div>
  );
};
