import React, { useEffect, useState } from 'react';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { MenuCard } from './MenuCard';
import { menuType } from '../type';
import { usePlaylistWatchModal } from 'pages/PracticePage/hooks/usePlaylistWatchModal';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import { decideMenuByGrade } from 'components/MenuConst/DecideMenu';

type Props = {
  runner: RunnerType;
};

type handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => void;

export const AllMenu = ({ runner }: Props) => {
  let menuList: menuType[] = [];

  for (let g = 0; g <= runner.grade; g++) {
    const menu = decideMenuByGrade(g);
    menuList = menuList.concat(menu);
  }

  const [searchWord, setSearchWord] = React.useState('');
  const [selectedMenu, setSelectedMenu] = useState<menuType | undefined>(
    undefined,
  );

  useEffect(() => {
    handleSearch();
  }, [searchWord]);

  const [menusToShow, setMenusToShow] = React.useState(menuList);
  const handleChange: handleInputChange = (e) => {
    setSearchWord(e.target.value);
  };
  const handleSearch = () => {
    let _videos = menuList.filter((m) => {
      return (
        m.title.indexOf(searchWord) !== -1 ||
        (!!m.hitWordList &&
          m.hitWordList.some(
            (word) =>
              searchWord.indexOf(word) !== -1 ||
              word.indexOf(searchWord) !== -1,
          ))
      );
    });
    setMenusToShow(_videos);
    console.log(_videos.length);
  };

  const { forceModalOpen, MenuWatchModal } =
    usePlaylistWatchModal(selectedMenu);

  const handleMenuClick = (m: menuType) => () => {
    forceModalOpen(true);
    setSelectedMenu(m);
  };

  return (
    <>
      {MenuWatchModal}
      <div style={{ paddingTop: '16px', paddingBottom: '10px' }}>
        <TextField
          variant="outlined"
          label="練習メニューをさがす"
          style={{
            width: '95%',
            marginLeft: '10px',
          }}
          multiline
          value={searchWord}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton style={{ padding: '4px' }}>
                  {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
                  <SearchIcon
                    onClick={handleSearch}
                    color="primary"
                    size="small"
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      {menusToShow.length < 1 ? (
        <div style={{ margin: '12px' }}>検索結果がありません</div>
      ) : (
        <div>
          {menusToShow.map((m) => {
            return (
              <MenuCard menuPlaylist={m} handleMenuClick={handleMenuClick(m)} />
            );
          })}
        </div>
      )}
    </>
  );
};
