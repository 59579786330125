// 10級パッケージの7級
type ckptTypeForGrade_13_20kyu =
  | 'よい姿勢'
  | '足を体の前で回す'
  | '足のつき方'
  | 'うで振り'
  | 'スピードを出す';

export const ckptForGrade_13_20kyu: ckptTypeForGrade_13_20kyu[] = [
  'よい姿勢',
  '足を体の前で回す',
  '足のつき方',
  'うで振り',
  'スピードを出す',
];

export const requirement_13_20kyu: ckptTypeForGrade_13_20kyu[] = [
  'よい姿勢',
  '足を体の前で回す',
];
