import {
  grades,
  ScoreEditType,
  //eslint-diable-next-line
} from 'components/MenuConst/GradeGoalConst';
import { VideoType } from 'adapters/repositories/typeDefinition';
import { crtComment20th_20 } from './grade_0';
import { crtComment19th_20 } from './grade_1';
import { crtComment18th_20 } from './grade_2';
import { crtComment17th_20 } from './grade_3';
import { crtComment16th_20 } from './grade_4';
import { crtComment15th_20 } from './grade_5';

export const autoCommentFor20thPkgs = (
  chosenVideo: VideoType,
  score: ScoreEditType,
  kyuOfVideo: grades,
) => {
  console.log(kyuOfVideo);
  const runnerInfo = chosenVideo.upload_runner;
  const runnerName = runnerInfo.username;
  const keisyo = runnerInfo.gender === '男性' ? 'くん' : 'ちゃん';
  const yobina = runnerName + keisyo;
  let comment = '';

  //// 復習の島にいない場合
  if (kyuOfVideo === '20') {
    comment = crtComment20th_20(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '19') {
    comment = crtComment19th_20(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '18') {
    comment = crtComment18th_20(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '17') {
    comment = crtComment17th_20(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '16') {
    comment = crtComment16th_20(runnerInfo, score, yobina);
  }

  if (kyuOfVideo === '15') {
    comment = crtComment15th_20(runnerInfo, score, yobina);
  }

  return comment + crt_line4(chosenVideo.video_memo);
};

function crt_line4(video_memo: string | undefined) {
  if (!video_memo) {
    return '来週からはよかったら質問してね！！';
  }

  return `質問してくれた「${video_memo}」という点ですが、`;
}
