import React, { FC, useCallback } from 'react';
import { ModalWrapper } from 'components/modals/ModalWrapper';
import { Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import waiting from 'static/lp/waiting.png';
import { formatTimeFromBackend } from 'utils/funcs/formatTimeFromBackend';

type videoInfoType = {
  filePath: string;
  finishTime: string;
};

export const useSendCompleteModal = (videoInfo: videoInfoType) => {
  const [open, setOpen] = React.useState(false);

  const toggleModalOpen = () => {
    setOpen(!open);
  };

  const forceModalOpen = useCallback((openOrClose: boolean) => {
    setOpen(openOrClose);
  }, []);

  return {
    forceModalOpen: forceModalOpen,
    toggleModalOpen: toggleModalOpen,
    SendCompleteModal: (
      <SendCompleteModal
        isModalOpen={open}
        // isModalOpen={open}
        setIsModalOpen={setOpen}
        filePath={videoInfo.filePath}
        finishTime={videoInfo.finishTime}
      />
    ),
  };
};

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  filePath: string;
  finishTime: string;
};

const SendCompleteModal: FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  filePath,
  finishTime,
}) => {
  const navigate = useNavigate();

  const domain =
    process.env.REACT_APP_FRONT_DOMAIN ||
    'https://test.d3c7spy4c3mn0o.amplifyapp.com/';

  return (
    <ModalWrapper isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}>
      <div style={{ maxWidth: '90vw' }}>
        <div style={{ fontSize: '14pt', color: '#424242' }}>
          送信が完了しました！
          <div style={{ fontSize: '10pt', margin: '6px 0 10px 0' }}>
            予想時刻：{formatTimeFromBackend(finishTime).time}
          </div>
        </div>

        <div style={{ margin: '24px 0 12px 0' }}>
          <Button variant="contained" color="secondary" size="large">
            <a
              style={{
                color: 'white',
                fontWeight: 'bold',
                textDecoration: 'none',
              }}
              href={`${domain}fifty_result/${filePath}`}
              target="_blank"
              rel="noreferrer"
            >
              結果画面へ &nbsp;
            </a>
            <PlayCircleFilledWhiteIcon style={{ color: '#ffff0f' }} />
          </Button>
        </div>
        <div
          style={{
            textAlign: 'left',
            fontSize: '10pt',
            margin: '10px 10px 10px 10px',
          }}
        >
          ※ 2人目以降の動画を送る場合は、まずは「結果画面へ」をクリックしてから
          <span
            style={{ color: '#4169e1', fontWeight: 'bold', fontSize: '10pt' }}
            onClick={() => {
              navigate('/introduction');
            }}
          >
            &nbsp; コチラ &nbsp;
          </span>
          をクリックして今と同じ流れで動画を送信！
        </div>
        <img src={waiting} width="80%" alt="こども" />
      </div>
    </ModalWrapper>
  );
};
