import React, { FC } from 'react';
import {
  Button,
  TextField,
  Grid,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { ValidationMsgType } from './ErrorCheckLogic';
import { RegisterStepper } from './Stepper';
import { PasswordInput } from './components/PasswordInputForm';
import classes from './registerpage.module.css';
import register_background from 'static/register_background.jpg';
import { HeightInput } from './components/HeightInput';
import { GenderInput } from './components/GenderInput';
import { CustomizedTextInput } from './components/CustomizedTextInput';
import { BirthdayInput } from './components/BirthdayInput';
import { AccountInfoType } from 'features/auth/types';

type infoKeys =
  | 'userName'
  | 'email'
  | 'password'
  | 'confirmPassword'
  | 'gender'
  | 'height'
  | 'showPassword';

type inputEvent = React.ChangeEvent<HTMLInputElement>;
type handleInputChange = (keyName: infoKeys) => (e: inputEvent) => void;
type heightChangeFunc = (
  event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
) => void;

type Props = {
  accountInfo: AccountInfoType;
  handleInfoChange: handleInputChange;
  handleHeightChange: heightChangeFunc;
  validationMessage: ValidationMsgType;
  handleClickShowPassword: () => void;
  isFormComplete: boolean;
  isUploading: boolean;
  goToLogin: () => void;
  handleSendClick: () => void;
};

export const PCRegisterView: FC<Props> = ({
  accountInfo,
  handleInfoChange,
  handleHeightChange,
  validationMessage,
  handleClickShowPassword,
  isFormComplete,
  isUploading,
  goToLogin,
  handleSendClick,
}) => {
  return (
    <Grid container>
      <Grid item xs={2}></Grid>
      <Grid item xs={8}>
        <div className={classes.registerWrapper}>
          <div className={classes.registerBack}>
            <img src={register_background} alt="check" width="99%"></img>
          </div>
          <div>
            <RegisterStepper activeStep={0} />
            <div style={{ textAlign: 'left', marginLeft: '10vw' }}>
              <CustomizedTextInput
                value={accountInfo.userName}
                label="&nbsp;おなまえ（下の名前）"
                handleChange={handleInfoChange('userName')}
                helperText={validationMessage.userName}
                placeHolder="（例）ひかる"
              />
              <CustomizedTextInput
                value={accountInfo.email}
                label="&nbsp;email"
                handleChange={handleInfoChange('email')}
                helperText={validationMessage.email}
                placeHolder=""
              />

              <div style={{ marginTop: '20px', maxWidth: '50vw' }}>
                <div style={{ marginBottom: '20px' }}>
                  <GenderInput
                    gender={accountInfo.gender}
                    handleChange={handleInfoChange('gender')}
                  />
                </div>

                <div style={{ marginBottom: '20px' }}>
                  <HeightInput
                    handleHeightChange={handleHeightChange}
                    height={accountInfo.height}
                  />
                </div>
              </div>
              <BirthdayInput
                accountInfo={accountInfo}
                handleInfoChange={handleInfoChange}
              />
              <div style={{ marginTop: '30px' }}>
                <PasswordInput
                  title="&nbsp;パスワード"
                  accountInfo={accountInfo}
                  value={accountInfo.password}
                  handleClickShowPassword={handleClickShowPassword}
                  handleInfoChange={handleInfoChange('password')}
                  validationMsg={validationMessage.password}
                />

                {/* <PasswordInput
              title="&nbsp;パスワード再入力"
              accountInfo={accountInfo}
              value={accountInfo.confirmPassword}
              handleClickShowPassword={handleClickShowPassword}
              handleInfoChange={handleInfoChange('confirmPassword')}
              validationMsg={validationMessage.confirmPassword}
            /> */}
              </div>
            </div>
            <div style={{ textAlign: 'left', marginLeft: '10vw' }}>
              {isFormComplete && isUploading && (
                <Button
                  variant="contained"
                  disabled
                  style={{ marginTop: '30px' }}
                >
                  登録 <CircularProgress size={20} />
                </Button>
              )}
              {isFormComplete && !isUploading && (
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '30px' }}
                  onClick={handleSendClick}
                >
                  登録
                </Button>
              )}
              {!isFormComplete && (
                <Button
                  variant="contained"
                  disabled
                  style={{ marginTop: '30px' }}
                >
                  登録
                </Button>
              )}
            </div>
            <div style={{ marginTop: '30px' }}>
              <Typography style={{ fontSize: '9pt' }}>
                アカウントをお持ちの方は
                <span className="textlink" onClick={goToLogin}>
                  ログイン
                </span>
                画面へ
              </Typography>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={2}></Grid>
    </Grid>
  );
};
