import { RunnerType } from 'adapters/repositories/typeDefinition';
import { convertRunnerGrade } from 'components/constants';
import { grades, grades5 } from '../GradeGoalConst';

export const nonFukushuGrades: grades[] = [
  '20',
  '19',
  '18',
  '16',
  '15',
  '13',
  '12',
  '11',
  '10',
  '9',
  '8',
  '6',
  '5',
  '3',
  '2',
  '1',
];

export const calcTarget = (runner: RunnerType) => {
  const grade = convertRunnerGrade(runner);
  return gradeTargetDict_5[grade];
};

type gradeTargetDict20Type = {
  [key in grades]: string;
};
export const gradeTargetDict_20: gradeTargetDict20Type = {
  20: 'Y字スプリント', //外
  19: '壁押しドリル', //家
  18: '3歩ハイニー', // 家
  17: '今までの復習', //外
  16: 'せまめのマーク走', //外
  15: '30mダッシュ', //外、ここまで変更
  14: '30mダッシュ', // 外
  13: 'スタートの構え', // 外
  12: '壁押し上級', // 家
  11: 'スタートダッシュ', // 外
  10: 'Y字スプリント', //外
  9: '壁押しドリル', //家
  8: '3歩ハイニー', // 家
  7: '30mダッシュ', //外
  6: '自転車ドリル',
  5: '3ステップ',
  4: '30mダッシュ', // 外
  3: 'スタートの構え', // 外
  2: '壁押し上級', // 家
  1: 'スタートダッシュ', // 外
};

type gradeTargetDict5Type = {
  [key in grades5]: string;
};
export const gradeTargetDict_5: gradeTargetDict5Type = {
  5: 'かけっこ診断に挑戦！',
  4: '「姿勢」をマスター', // 外
  3: '「足の動き」をマスター', // 外
  2: '「足の流れ」をマスター', // 家
  1: 'スタートダッシュ', // 外
};
