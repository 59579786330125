import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import 'index.css';
import 'App.css';
import sampleThumbnail from 'static/sample_thumbnail.png';

export const CardForFirstLogin = () => {
  const navigate = useNavigate();

  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; variant: string; id: st... Remove this comment to see the full error message
    <div variant="outlined" id="videoCard">
      <Grid container>
        <Grid item xs={4}>
          <div>
            <img
              alt=""
              onClick={() => {
                navigate(`/result-share/sample`);
              }}
              width="95%"
              src={sampleThumbnail}
            ></img>
          </div>
        </Grid>

        <Grid item xs={8}>
          <div style={{ textAlign: 'left' }}>
            <div
              style={{ fontSize: '11pt', display: 'flex' }}
              onClick={() => {
                navigate(`/result-share/sample`);
              }}
            >
              サンプル動画を見る
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export function formatDate(string: string) {
  const TokyoDate = new Date(string);
  let nengappi =
    TokyoDate.getFullYear() +
    '年' +
    (TokyoDate.getMonth() + 1) +
    '月' +
    TokyoDate.getDate() +
    '日' +
    TokyoDate.getHours() +
    '時' +
    TokyoDate.getMinutes() +
    '分';

  return nengappi;
}
