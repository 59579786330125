import {
  dribbleJump,
  basePositinJump,
  pongpongJog,
  y_sprint,
  y_practice,
  spring,
  arm_swing,
  howToStartPose,
} from './menus/grade_1/4kyu_for_5pkg';

import { menuType } from 'pages/PracticePage/type';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import { convertRunnerGrade } from 'components/constants';
import {
  arm_swing_19th,
  basePositionWalk_19th,
  kabeoshiBasic,
  re_basePositinJump,
  singleLegRotation,
  threeHighKnee,
  yHighkneeSprint,
  darumasan,
  highKneeDash,
  stepQuick,
} from './menus/grade_2/3kyu_for_5pkg';
import {
  arm_swing_Walk_16th,
  henkeidash_15th,
  markRun_16th,
  touritsu,
  towelhighknee_16th,
  train,
} from './menus/grade_3/2kyu_for_5pkg';

type decideMenu = (runner: RunnerType | undefined) => menuType[];

export const decideMenu: decideMenu = (runner) => {
  // 現在の級とstay_timeに応じて出る。

  if (!runner || !runner.grade) {
    return [dribbleJump, basePositinJump, pongpongJog, y_sprint];
  }

  const kyu = convertRunnerGrade(runner);

  switch (kyu) {
    case '4':
      return [
        dribbleJump,
        basePositinJump,
        pongpongJog,
        y_sprint,
        y_practice,
        spring,
        arm_swing,
        howToStartPose,
      ];

    case '3':
      return [
        re_basePositinJump,
        basePositionWalk_19th,
        kabeoshiBasic,
        threeHighKnee,
        arm_swing_19th,
        yHighkneeSprint,
        darumasan,
        singleLegRotation,
        // highKneeDash,
        stepQuick,
      ];

    case '2':
      return [
        arm_swing_Walk_16th,
        towelhighknee_16th,
        train,
        markRun_16th,
        henkeidash_15th,
        touritsu,
        stepQuick,
        highKneeDash,
      ];

    default:
      return [dribbleJump, basePositinJump, pongpongJog, y_sprint];
  }
};

export const decideMenuByGrade = (grade: number): menuType[] => {
  // 現在の級とstay_timeに応じて出る。

  switch (grade) {
    case 1:
      return [
        dribbleJump,
        basePositinJump,
        pongpongJog,
        y_sprint,
        y_practice,
        spring,
        arm_swing,
        howToStartPose,
      ];

    case 2:
      return [
        re_basePositinJump,
        basePositionWalk_19th,
        kabeoshiBasic,
        threeHighKnee,
        arm_swing_19th,
        yHighkneeSprint,
        darumasan,
        singleLegRotation,
        // highKneeDash,
        stepQuick,
      ];

    case 3:
      return [
        arm_swing_Walk_16th,
        towelhighknee_16th,
        train,
        markRun_16th,
        henkeidash_15th,
        touritsu,
        stepQuick,
        highKneeDash,
      ];
    default:
      return [];
  }
};
