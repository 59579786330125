import React from 'react';

import { useNavigate } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Modal, Fade, Backdrop, Box } from '@mui/material';
import './sticker.css';

import rabbit from 'static/sticker/rabbit.png';
import cheetah from 'static/sticker/cheetah.png';
import elephant from 'static/sticker/elephant.png';
import squirrel from 'static/sticker/squirrel.png';
import zeebra from 'static/sticker/zebra.png';

export const animalDict = {
  20: squirrel,
  19: rabbit,
  18: rabbit,
  17: elephant,
  16: elephant,
  15: elephant,
  14: zeebra,
  13: zeebra,
  12: cheetah,
  11: cheetah,
  10: squirrel,
  9: rabbit,
  8: rabbit,
  7: elephant,
  6: elephant,
  5: elephant,
  4: zeebra,
  3: zeebra,
  2: cheetah,
  1: cheetah,
  0: cheetah,
};

const style = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: '#f8f8ff',
    border: '4px solid #ff1493',
    borderRadius: '5%',
    boxShadow: 24,
    p: 4,
    overflowY: 'scroll',
    textAlign: 'center',
    height: `${window.parent.screen.height * 0.5}px`,
  },
};

export const StickerModal = (props: any) => {
  const navigate = useNavigate();
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const animal = animalDict[props.grade];
  const [isStickerOn, setIsStickerOn] = React.useState(false);

  const handleClose = () => {
    // スタンプ押してなかったら消えないように
    const target = document.getElementById('sticker');
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    target.classList.contains('my-stamp-on')
      ? navigate(`/logs`)
      : props.setIsModalOpen(true);
  };

  const handleClick = () => {
    const target = document.getElementById('sticker');
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    target.classList.add('my-stamp-on');
    console.log('click');
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    console.log(target.classList);
    setIsStickerOn(true);
  };
  return (
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    <Modal
      className={style.modal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.isModalOpen}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.isModalOpen}>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <Box sx={style.paper}>
          <h3 id="transition-modal-title">ほうこく完了！</h3>

          <h3 id="transition-modal-title">練習のしるしにスタンプを押そう！</h3>

          <div id="transition-modal-description">
            <figure
              className="wp-block-image size-large is-resized my-stamp"
              id="sticker"
            >
              <img
                src={animal}
                alt="うさぎ"
                className="stamp2 wp-image-8151"
                width="130"
                height="130"
                onClick={handleClick}
              />
            </figure>
          </div>

          <div style={{ marginTop: '20px' }}>
            {isStickerOn ? (
              <Button
                onClick={handleClose}
                variant="contained"
                color="primary"
                size="medium"
              >
                閉じる
              </Button>
            ) : (
              <span style={{ color: '#222222', fontSize: '12pt' }}>
                （↑をタップ）
              </span>
            )}
          </div>

          <div style={{ fontSize: '8pt', marginTop: '50px' }}>
            <a
              href="https://www.flaticon.com/free-stickers/horse"
              title="horse stickers"
            >
              This sticker is created by Stickers - Flaticon
            </a>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};
