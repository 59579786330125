import React, { memo, useState, FC } from 'react';
import classes from 'pages/FiftyCheckLP/fifty_check.module.css';
import joy_boy from 'static/materials/joy_boy.png';
import onegai from 'static/lp/onegai.png';
import { YoutubeVideoPlayer } from 'utils/components/YoutubeVideoPlayer';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Divider } from '@material-ui/core';
import { RunnerType } from 'adapters/repositories/typeDefinition';
import { decideOnboardState } from 'pages/MemberHome/func/decideOnboardState';

type Props = {
  runner: RunnerType | undefined;
};

export const DiagnoseExplanation: FC<Props> = memo(({ runner }) => {
  const onboardState = !!runner && decideOnboardState(runner);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleClick = () => {
    setIsChecked(!isChecked);
    if (!!runner && !isChecked && onboardState === 'beforePractice') {
      localStorage.setItem(String(runner.id), 'beforeChallenge');
    }
  };

  return (
    <>
      <div className={classes.background}>
        {/* 少年 */}
        <div className={classes.announceBarWrapper}>
          <div className={classes.announceBar}>
            <div style={{ width: '20%' }}>
              <img src={joy_boy} alt="" width="90%"></img>
            </div>
            <div style={{ width: '80%' }}>
              <div className={classes.blackBorderSmall}>
                ここではかけっこ診断のやり方を説明するよ！読んだら画面の一番下の
                <span style={{ color: '#ff8c00' }}>
                  オレンジのチェックボックス
                </span>
                を押してね！
              </div>
            </div>
          </div>
        </div>

        {/* ここからごつい */}

        <div className={classes.howToDiagnoseWrapper}>
          <div style={{ fontWeight: 'bold', fontSize: '12pt' }}>
            <span style={{ color: 'red', fontWeight: 'bolder' }}>ばしょ</span>
            <span>：30m程度の直線ならどこでもOK！</span>
            <br />
            <div style={{ fontWeight: 'normal', fontSize: '10pt' }}>
              厳密に30mを測る必要はありません！下の動画のように撮れればどこでも大丈夫です。
            </div>
            <div style={{ fontWeight: 'normal', fontSize: '10pt' }}>
              &nbsp;公園やお家の前の道路で撮影される方が多いです。
            </div>
          </div>

          <div className={classes.subtitle}>
            <span style={{ color: 'red', fontWeight: 'bolder' }}>ふくそう</span>
            <span>：動きの見えやすい服装で走ろう！</span>
            <br />
            <div style={{ fontWeight: 'normal', fontSize: '10pt' }}>
              &nbsp;スカートやダボダボの服は避けて下さい。
            </div>
          </div>

          <div className={classes.subtitle}>
            <span style={{ fontWeight: 'bolder' }}>その他</span>
            <span>：走るのは1人で明るい場所で撮ろう！</span>
            <br />
            <div style={{ fontWeight: 'normal', fontSize: '10pt' }}>
              &nbsp;※
              AIがお子様を判別できなくなるため、お子様同士の並走もお控え下さい。
            </div>
          </div>

          <div style={{ margin: '16px 0 8px 0' }}>
            <img src={onegai} width="90%" alt="おねがい" />
          </div>
          <div style={{ fontWeight: 'bold', fontSize: '12pt' }}>
            撮影前に以下の動画を
            <span style={{ color: '#ff0000' }}>必ず一度</span>ご覧下さい！
          </div>

          <YoutubeVideoPlayer
            srcURL="https://www.youtube.com/embed/X4x_Adf490I"
            ratio={0.95}
          />

          <div className={classes.reminder}>
            撮影した動画は <span style={{ color: '#ff0000' }}>必ず一度</span>
            &nbsp;ご確認下さい！
            <div style={{ textAlign: 'left', margin: '8px 8px 8px 8px' }}>
              ✅ スタートから20m地点を撮影している。
              <br />✅ 背景に走っている人が映っていない。
              <br />✅ 6歩程度映っている。
            </div>
            <Divider style={{ margin: '12px 0px 12px 0px' }} />
            <div style={{ textAlign: 'center' }}>
              <div>
                かけっこ診断のやり方がわかったら
                <br />
                下の四角の中をチェック！
              </div>
              {!isChecked && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div>
                    <CheckBoxOutlineBlankIcon
                      onClick={handleClick}
                      style={{ color: '#ffa500', fontSize: '44pt' }}
                    />
                  </div>
                  <div>
                    <SentimentNeutralIcon
                      style={{ color: '#ffa500', fontSize: '44pt' }}
                    />
                  </div>
                </div>
              )}{' '}
              {isChecked && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div>
                    <CheckBoxIcon
                      style={{ color: '#3cb371', fontSize: '44pt' }}
                      onClick={handleClick}
                    />
                  </div>
                  <div>
                    <SentimentVerySatisfiedIcon
                      style={{ color: '#3cb371', fontSize: '44pt' }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div>
            <br />
          </div>
        </div>
      </div>
    </>
  );
});
