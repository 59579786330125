import React from 'react';
import classes from '../guidanceTools.module.css';

import { Button } from '@material-ui/core';
import { onboardType } from '../type';

export const LetterPopover = (
  onboardState: onboardType,
  onClickFunc: () => void,
) => {
  if (onboardState === 'beforeRead') {
    return (
      <div className={classes.popover}>
        <span style={{ fontWeight: 'bold', fontSize: '14pt' }}>
          かけっこ診断の結果がとどいたよ！
        </span>
        <div
          style={{
            margin: '24px 0 12px 0',
            textAlign: 'center',
            width: '100%',
          }}
        >
          <Button
            onClick={onClickFunc}
            style={{
              width: '60%',
              fontWeight: 'bold',
              fontSize: '12pt',
              backgroundColor: '#0000ff',
              color: 'white',
            }}
            variant="contained"
          >
            {'みる！'}
          </Button>
        </div>
      </div>
    );
  }
  if (onboardState === 'Loupe') {
    return (
      <div className={classes.popover}>
        診断結果が出るまでもう少し待っててね！
      </div>
    );
  }
  return <div className={classes.popover}>黄色いボタンを押してみよう！</div>;
};
