import React, { FC, useEffect } from 'react';
import { Card } from '@material-ui/core';

import { VideoType } from 'adapters/repositories/typeDefinition';
import { EachScoreViewer } from './EachScoreViewer';
import { AIScore } from 'components/MenuConst/thresholds';
import apology from 'static/apology.png';

//eslint-disable-next-line

type Props = {
  video: VideoType;
};

export const ResultComponent: FC<Props> = ({ video }) => {
  const detailCheckPoints = JSON.parse(
    (!!video?.score?.ai_eval && video.score.ai_eval) || '',
  ) as AIScore;
  console.log(detailCheckPoints);

  if (detailCheckPoints.inclination_raw === -1)
    return (
      <div>
        <img alt="" src={apology} width="80%"></img>
      </div>
    );

  return (
    <div style={{ marginTop: '8px' }}>
      <div style={{ width: '93%', marginLeft: '3%' }}>
        {/* // FIXME: compareができるまではハードコード */}
        <Card>
          <img
            alt=""
            src={`https://shimotakaido.s3.amazonaws.com/compare_videos/${video.file}.gif`}
            width="99%"
          ></img>
        </Card>
      </div>
      {!video.score && <div>現在診断中です。もう少々お待ちください。</div>}
      {!!video.score && (
        <>
          {/* <EstimateTimeViewer video={video} /> */}
          <EachScoreViewer
            score={detailCheckPoints}
            speed={video.score.real_speed}
          />
        </>
      )}
    </div>
  );
};
