import React, { FC, memo, useState } from 'react';

import { useGetVideoByRunner } from 'hooks/useGetVideoByRunner';
import { Card } from '@mui/material';

import { VideoContext } from 'contexts/VideoContext';
import { StickerCalendar } from './components/StickerCalendar';
import { CalendarHeader, yearMonthType } from './components/CalendarHeader';
import { getVideoMonthly } from './func/simpleFuncs';
import { TabInfo } from 'components/TabInfo';
import { VideoListPage } from './components/VideoListPage';
import { useNavigate } from 'react-router-dom';
import { RunnerType } from 'adapters/repositories/typeDefinition';

type Props = {
  runner: RunnerType | undefined;
};
export const LogPage: FC<Props> = memo(({ runner }) => {
  const { videoList } = React.useContext(VideoContext);
  useGetVideoByRunner(runner);
  const navigate = useNavigate();

  const [yearMonth, setYearMonth] = useState<yearMonthType>({
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });

  const videoMonthly = getVideoMonthly(
    videoList,
    yearMonth.year,
    yearMonth.month,
  );

  return (
    <>
      <TabInfo labels={['練習のきろく', 'カレンダー']}>
        <>
          <VideoListPage />
        </>
        <>
          {!runner && <Announce />}

          <CalendarHeader
            yearMonth={yearMonth}
            setYearMonth={setYearMonth}
            monthlyTime={videoMonthly.length}
          />

          <div style={{ textAlign: 'center' }}>
            <StickerCalendar
              year={yearMonth.year}
              month={yearMonth.month}
              videoMonthly={videoMonthly}
            />
          </div>
          {!runner && (
            <div
              style={{
                marginTop: '12px',
                marginBottom: '50px',
                textAlign: 'center',
              }}
            >
              購入ページにお戻りの際は
              <span
                style={{ fontWeight: 'bold', color: '#4169e1' }}
                onClick={() => navigate('/purchase')}
              >
                ココをクリック
              </span>
            </div>
          )}
        </>
      </TabInfo>
      <div style={{ marginTop: '80px' }}></div>
    </>
  );
});

const Announce = () => {
  return (
    <Card
      style={{
        padding: '12px',
        margin: '10px',
        backgroundColor: '#ccffcc',
        color: '#626262',
      }}
    >
      練習をきろくするとカレンダーに
      <span style={{ fontWeight: 'bold' }}>⭕️</span>
      <span style={{ fontWeight: 'bold' }}>スタンプ</span>
      がおされます。スタンプをタップするとその日の練習をかくにんできます！
    </Card>
  );
};
